<template>
    <v-toolbar
        class="elevation-2 sticky"
        :style="{ top: ($vuetify.breakpoint.mdAndUp ? 64 : 56) + 'px' }"
        :extended="extended"
        :dense="$vuetify.breakpoint.smAndDown"
    >
        <v-expand-x-transition>
            <v-app-bar-nav-icon icon v-if="backTo" :to="backTo" exact>
                <v-icon>mdi-arrow-left</v-icon>
            </v-app-bar-nav-icon>
        </v-expand-x-transition>
        <v-chip v-if="label" :color="labelColor" class="mr-3" :small="$vuetify.breakpoint.smAndDown" label>{{label}}</v-chip>
        <v-toolbar-title>
          <span v-if="breadCrumbs.length > 0 ">{{breadCrumbs[breadCrumbs.length - 1].label}}</span>
          <span v-else-if="title">{{ title }}</span>
          <v-breadcrumbs
              v-if="breadCrumbs && breadCrumbs.length > 0"
              :items="formattedBreadCrumbs"
              divider="/"
          ></v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <slot />
        <v-btn icon v-if="refreshButton && !refreshedPressed" @click="refresh">
            <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon v-if="refreshButton && refreshedPressed" disabled>
          <v-icon >mdi mdi-refresh mdi-spin</v-icon>
        </v-btn>
          <template v-if="$slots.extension" v-slot:extension>
              <slot name="extension" />
          </template>
    </v-toolbar>
</template>

<script>
export default {
    name: 'Toolbar',
    data: () => ({
        toolbarWidth: '100%',
        //toolbarHeight: 64, Note sure what this is.
        refreshedPressed: false,
        refreshTimeout:null,
    }),
    methods: {
        refresh(){
            if(this.refreshTimeout == null){
              clearTimeout(this.refreshTimeout);
            }

            this.refreshedPressed = true;
            this.refreshTimeout = setTimeout(() => {
              this.refreshedPressed = false
            }, 2000); //note this spin effect on the icon has a 2 second rotation. So 2 second completes the spin for visual pleasure.
            document.dispatchEvent(
                new CustomEvent('refreshRequested', { detail: this.$route.name })
            );
        }
    },
    computed: {
        width() {
            let spacer = document.getElementById('spacer');
            return spacer ? spacer.offsetWidth : '100%';
        },
         formattedBreadCrumbs( ){
          let ret = [];
          let item;

          for (const element of this.breadCrumbs) {
            item = {
              text: element.label,
              disabled: false,
              exact:true,
            }
            if(element.name){
              item.to = {
                name:element.name,
                params: element.params
              }
            }
            ret.push(item);
          }

           /**
            * Note that there is some history here thats made the breadcrumbs a bit out of wack.
            * Generally speaking the breadcrumbs are auto generated based on the parent page component.
            * If no addons have been added to the breadcrumbs then we need to pull the title from the route.
            * However we only need to pull the title from the router if the current route is not included in the breadcrumbs.
            * If the current route is in the breadcrumb then it is already catered for and we do not need the route title.
            *
            * Generally speaking if a title has a dynamic value ie Extension (bced34567) then it will be part of the breadcrumbs.
            * So the Extension summary page we want the title to be 'Extension (bcex34567)' however the title from the router is 'Summary'.
            * In this case we do not show the route title.
            * However if we are on the Extension device list page we take the 'Device List' title from the router.
            * -
            */

           if(this.breadCrumbs == undefined
               ||
               this.breadCrumbs.length == 0 //no bread crumbs
               ||
               !this.breadCrumbs[this.breadCrumbs.length -1].name //last bread crumb has not route
               ||
               this.breadCrumbs[this.breadCrumbs.length -1].name != this.$route.name //the last breadcrumb does not match the current route
           ){
             if(this.title){
               item = {
                 text: this.title,
                 disabled: true,
               }
               ret.push(item);
             }
           }
          return ret;
       }
    },
    watch: {
        title() {
            document.title = this.title + " - Breeze Connect" || "Breeze Connect";
        }
    },
    props: {
        backTo: {
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        extended: {
            type: Boolean,
            default: false,
        },
        refreshButton: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        labelColor: {
            type: String,
            default: 'info',
        },
        breadCrumbs: {
          type: Array,
          default: function( ){
            return []
          },
        },
    },
    created() {
        document.title = this.title + " - Breeze Connect" || "Breeze Connect";
    }
};
</script>

<style scoped>
.sticky {
    position: sticky;
    position: -webkit-sticky;
    z-index: 3;
}
.v-app-bar-title__content {
    width: auto;
}
a:link, a{
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.v-breadcrumbs{
  padding:0px;

}
</style>
