<template>
    <v-row dense>
        <v-col cols="12" v-if="trunkData.i_account">
            <DIDListCard
                v-if="trunkData.bill_status !== 'C'"
                :trunkData="trunkData"
                :cust-id="trunkData.i_customer"
                :loading="isBusy"
                :filter="filter"
                @update="updateFilter"
                hide-status
                show-tools
                show-filters
                @addButtonTriggered="openDIDDialog"
            />
        </v-col>
      <FloatingButton tooltip="Add New DIDs" icon="mdi-phone-plus" @click="showNewDIDs=true" :loading="isBusy" />
      <!-- Note cannot use v-if="!trunkLoading" here has we need to have the dialog open to see DID message during the update process. -->
      <AddDIDDialog v-if="trunkData && customerData" :trunkData="trunkData" :customerData="customerData" v-model="showNewDIDs" processTypeSwitch="trunk" :validOptions="addDidOptions"/>
    </v-row>
</template>

<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import trunkDataMixin from '../../mixins/trunkDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialog from '@/components/dialogs/DID/AddDIDDialog';
export default {
    name: 'TrunkDIDs',
    mixins: [filterMixin, trunkDataMixin],
    components: {
      AddDIDDialog,
      FloatingButton,
      DIDListCard,
    },
    data: () => ({
        busy:false,
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        showNewDIDs: false,
        customerData: null,
    }),

  /**
   * This prevents page redirect if the AddDIDDialog is open.
   * This redirect happens on error.
   * Because the AddDIDDialog is processing multiple DIDs, any one of the requests can fail.
   * If one those request fail is seems to cause a redirect.
   * Hence this method prevents that redirect. Note this will display an error in the console RE the redirect.
   * At this point im not 100% why the page redirect then a route fails.
   *
   * @param to
   * @param from
   * @param next
   */
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs && this.trunkData && this.customerData) ? next(false) : next();
    },
    computed: {
      addDidOptions: function( ){
        let ret = {"admin":[1,2,3],"partner":[1],"customer":[1]};
        if(this.trunkData.isWholesaleManaged){
          ret = {"all":[1]};
        }
        return ret;
      },
      accountId: function (val) {
        if(this.trunkData){
          return this.trunkData.i_account;
        }
        return null;
      },
      isBusy: function( ){
        return this.trunkLoading || this.busy;
      },
    },
  created() {
    if(this.trunkData && this.trunkData.i_customer){
      //Note that this does not fire on page load as the trunkData is not populated yet when this creates.
      //We rely on the watch accountId to populate the customer on page load.
      //We need the create version when switching between routes. As the trunkData is in storage and does not change between route change.
      //Problem is both of these fire if the TrunkDid page is refreshed. Else only one should fire (create or watch).
      this.requestCustomerData(this.trunkData.i_customer);
    }
  },
  watch: {
      accountId: function (val) {
        this.requestCustomerData(this.trunkData.i_customer);
      },
    },

  methods: {
      openDIDDialog( ){
        this.showNewDIDs = true;
      },
    async requestCustomerData(id) {
      this.busy = true;
      const response = await this.Api.send('get','customers/' + id, false);
      if (response.success) {
        this.customerData = response.data;
      }
      this.busy = false;
    },
  }

};
</script>

<style scoped></style>
