<template>
    <ComplexDialog
        v-model="visible"
        :title="'View Plan Details - '+trunkLabel(data)+' '+ data.id"
    >
      <TrunkProductInfoSummary
        v-if="data.i_account && products.length != 0"
        :trunkData="data"
        :products="products"
        :showCallRated="data.account_type == 'FAX-TO-MAIL' ? false : true"
      />
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                @click="visible=false"
                text
            >Close</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ComplexDialog from '../templates/ComplexDialog';
    import dialogMixin from '../../../mixins/dialogMixin';
    import TrunkProductInfoSummary from "../../pieces/Trunk/TrunkProductInfoSummary";
    import trunkDataMixin from "../../../mixins/trunkDataMixin";
    export default {
        name: 'ViewTrunkProductsDialog',
        mixins: [dialogMixin, trunkDataMixin],
        components: {TrunkProductInfoSummary, ComplexDialog},
        props: {
            data: {
                type: Object,
                default: null
            },
          products: {
            type: Array,
            required:true,
          },
        },
      computed:{
          /*planData( ){
            let ret = {
              subProduct: this.data.planDetails.planTag,
              productOption: null
            }

            if(this.data.planDetails.addons[0]) {
              ret.productOption = this.data.planDetails.addons[0].tag;
            }
            return ret;

          }*/
      }
    };
</script>

<style scoped>

</style>