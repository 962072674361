<template>
    <ComplexDialog
        v-model="visible"
        :title="'View Plan Details - Trunk '+ trunkData != null ? trunkData.id : '' "
    >
        <TrunkPlanInfoSummary
            :products="products"
            v-if="trunkData && trunkData.i_account && products.length != 0"
            :trunkData="trunkData"
        />
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                @click="visible=false"
                text
            >Close</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ComplexDialog from '../templates/ComplexDialog';
    import dialogMixin from '../../../mixins/dialogMixin';
    import TrunkPlanInfoSummary from '../../pieces/Trunk/TrunkPlanInfoSummary';
    export default {
        name: 'ViewTrunkPlanDialog',
        mixins: [dialogMixin],
        components: { TrunkPlanInfoSummary, ComplexDialog },
        props: {
          products: {
            type: Array,
            required:true,
          },
          trunkData: {
            type: Object,
            default: null,
          },
        },
    };
</script>

<style scoped>

</style>