<template>
    <v-row dense>
        <v-col cols="12" v-if="customerLoading || busy" class="text-center">
          <BasicCard class="pa-2">
            <v-progress-circular indeterminate /> Loading...
          </BasicCard>
        </v-col>
        <v-col v-for="contact in data.data" :key="contact.id" cols="12" sm="6" lg="4">
            <ContactCard :data="contact" createUser :customerId="customerData.i_customer" @edit="editContact" :allowEdit="true"/>
        </v-col>
        <FloatingButton tooltip="New Contact" icon="mdi-plus" @click="createContact" :loading="customerLoading" />
        <AddContactDialog v-model="showNewContact" :title="((prefill == null || prefill.id == null) ? 'Add New Contact' : 'Edit Contact details')" :customerId="customerData.i_customer" :prefill="prefill"/>
    </v-row>
</template>

<script>
import ContactCard from '../../components/cards/ContactCard';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddContactDialog from "../../components/dialogs/AddContactDialog";
import apiMixin from '@/mixins/apiMixin';
import refreshListenerMixin from '@/mixins/refreshListenerMixin';
import BasicCard from '@/components/cards/templates/BasicCard';
import customerDataMixin from "../../mixins/customerDataMixin";
export default {
    name: 'CustomerContacts',
    components: { BasicCard, AddContactDialog, FloatingButton, ContactCard },
    mixins: [apiMixin, customerDataMixin, refreshListenerMixin],
    data: () => ({
        showNewContact: false,
        data: [],
        busy: false,
        prefill: null,
    }),
    watch: {
        customerLoading(value) {
          if (!value && this.customerData.i_customer) this.update();
        }
    },
    methods: {
        async update() {
            if (!this.customerData || !this.customerData.i_customer) return;
                this.busy = true;
                const response = await this.Api.send('get', 'customers/' + this.customerData.i_customer + '/contacts');
                this.data = response.success ? response.data : null;
                this.busy = false;
        },
        refreshRequested() {
          this.update();
        },
        editContact(data) {
          this.prefill = data;
          this.prefill.title = data.salutation;
          this.showNewContact = true;
        },
      createContact() {
        this.prefill = null;
        this.showNewContact = true;
      }
    },
    created() {
        this.update();
    }
};
</script>