<template>
    <ComplexDialog
        v-model="visible"
        :title="'Change Plan - '+label+' '+ data.id"
        :step="step"
        :close-confirmation="!!plan && step!==3"
        close-confirmation-message="The new plan you selected has not been applied yet."
        @step-next="next"
        :step-next-text="step===2 ? 'Apply' : undefined"
        :step-next-disabled="(step===1 && !plan)"
        @step-back="step--"
        @step-end="visible=false"
        :step-end="3"
        :max-width="950"
        :loading="busy"
    >
        <v-window v-model="step">
            <v-window-item :value="1">
                <p>Please choose the new plan to apply to this service.</p>
                <TrunkPlanForm
                    v-if="visible && data.customer_class_sip_trunk_type == 'default' && products && data.i_account && products.length > 0"
                    v-model="plan"
                    :show-nfr="customerData.nfr_limit > 0"
                    :products="products"
                    :trunkData="data"
                />
                <TrunkProductForm
                    v-model="plan"
                    v-if="data.i_account && products.length > 0 && data.customer_class_sip_trunk_type != 'default'"
                    :i_customer="data.i_customer" :trunkData="data" :products="products"
                    :showOptions="data.account_type == 'AUTO-ATTENDANT' ? false : true"
                    :type="data.account_type.toLowerCase()"
                />
            </v-window-item>
            <v-window-item :value="2">
                <v-alert type="info">The new plan will be applied effective immediately - plan charges will be pro-rated.</v-alert>
                <p>The new plan you have selected is summarized below.</p>
                <TrunkPlanInfoSummary v-if="step===2 && data.customer_class_sip_trunk_type == 'default'" :data="plan" :products="products"/>
              <TrunkProductInfoSummary v-if="step===2 && data.customer_class_sip_trunk_type != 'default'" :data="plan" :products="products" :customerData="this.customerData"/>
            </v-window-item>
            <v-window-item :value="3">
                <v-row no-gutters>
                    <v-col cols="12" class="text-center py-4">
                        <v-icon size="120" color="success"
                            >mdi-check-circle-outline</v-icon
                        >
                        <p class="text-h6">Plan changed successfully</p>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </ComplexDialog>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ComplexDialog from '../templates/ComplexDialog';
import TrunkPlanForm from '../../pieces/Trunk/TrunkPlanForm';
import TrunkPlanInfoSummary from '../../pieces/Trunk/TrunkPlanInfoSummary';
import customerDataMixin from "../../../mixins/customerDataMixin";
import TrunkProductForm from "../../pieces/Trunk/TrunkProductForm";
import TrunkProductInfoSummary from "../../pieces/Trunk/TrunkProductInfoSummary";
export default {
    name: 'ChangeTrunkPlanDialog',
    components: {
      TrunkProductInfoSummary,
      TrunkProductForm,
       TrunkPlanInfoSummary, TrunkPlanForm, ComplexDialog},
    mixins: [apiMixin, dialogMixin,customerDataMixin],
    data: () => ({
        step: 1,
        plan: null,
        busy: false,
        error: null,
        products:[],
    }),
  computed: {
    label() {
      return this.data.account_type_label;
    },
    accountType( ){
      return this.data.account_type;
    }
  },
    methods: {
      async getProducts( ){
        this.busy = true;
        let response = null;
        if(this.data.account_type.toLowerCase() == 'sip-trunk'){
          response = await this.Api.send('get', 'sip-trunks/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'hosted-extension'){
          response = await this.Api.send('get', 'extensions/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'ring-group'){
          response = await this.Api.send('get', 'ring-groups/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'fax-to-mail'){
          response = await this.Api.send('get', 'fax-to-mail/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'auto-attendant'){
          response = await this.Api.send('get', 'auto-attendant-accounts/products/'+this.data.i_customer);
        }else{
          alert('Products for account type ('+this.data.account_type+') not supported.');
        }
        this.busy = false;
        if(response.success){
          this.products = response.data;
        }
      },
        next() {
            if (this.step===2) {
                this.submit(this.plan);
            } else {
                this.step++;
            }
        },
        async submit(data) {
            this.busy = true;
            let response = null;
            let params = {};

            if(data.mainProduct) {
              params.mainProduct_i_product = data.mainProduct.i_product;
            }else if(this.account_type != 'SIP-TRUNK'){
              params.mainProduct_i_product = this.products[0].i_product;
            }

            params.subProduct_i_product = null;
            if(data.subProduct) {
              params.subProduct_i_product = data.subProduct.i_product;
            }
            params.productOption_i_product = null;
            if(this.plan.productOption) { //this can be null as it is the 3rd tier selection and not all SIP product have 3 tiers.
              params.productOption_i_product = data.productOption.i_product;
            }
            if(!params.productOption_i_product){ //above can set it to undefined which does not get submitted during the request.
              params.productOption_i_product = null;
            }
            if(this.data.customer_class_sip_trunk_type == 'default'){
              response = await this.Api.send('post','trunks/' + this.data.i_account + '/update_plan', params);
            }/*else if(this.data.account_type == 'HOSTED-EXTENSION'){
              response = await this.Api.send('put','extensions/' + this.data.extensionDetails.i_c_ext + '/plan', data);
            }*/else{
                response = await this.Api.send('put','trunks/' + this.data.i_account + '/plan', params);
            }

            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.step = 3;
            }
            this.busy = false;
        }
    },
    watch: {
        visible(value) {
            if (this.visible!==value){
              this.visible = value;
            }
            if (!value) {
                this.step = 1;
            }else{
              this.getProducts();
            }
        },
    },
    props: {
        data: {
            default: null
        },
        showNfr: {
            type: Boolean,
            default: false
        }
    },
};
</script>