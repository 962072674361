<template>
  <v-form v-model="valid">
    <v-row dense>
      <v-col cols="12" v-if="data.account_type != 'RING-GROUP'">
        <v-select outlined label="Call Forwarding Mode" :items="trunkSettingsData.forwardingModes" v-model="forwarding_mode" @change="updateForwardingMode" :rules="rules.required" hide-details />
        <v-alert type="warning" transition="fade" outlined v-if="forwarding_mode==='N'" class="mt-4 mb-0">Calls will not be forwarded if the SIP device is unregistered.</v-alert>
      </v-col>
      <v-col cols="12" v-else>
        <v-text-field
            :value="trunkSettingsData.forwardingModes.find(element => element.value == forwarding_mode).text"
            label="Call Forwarding Mode"
            readonly
            outlined
            hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" v-if="(forwarding_mode==='Y' || forwarding_mode==='F') && data.account_type != 'RING-GROUP'">
        <v-select class="mt-4" outlined label="Order" :items="trunkSettingsData.forwardingSequences" v-model="sequence" :rules="rules.required" hide-details />
        <v-alert type="warning" transition="fade" outlined v-if="sequence==='Weighted' && sum_of_weights!==100" class="mt-4 mb-0">Weights from all entries must add up to 100%!</v-alert>
      </v-col>
      <v-col cols="12" v-if="(forwarding_mode==='Y' || forwarding_mode==='F') && data.account_type == 'RING-GROUP'">
        <v-text-field
            :value="trunkSettingsData.forwardingSequences.find(element => element.value == sequence).text"
            label="Order"
            readonly
            outlined
            hide-details
        ></v-text-field>
      </v-col>
      <!-- settings -->
      <v-col cols="12" v-if="forwarding_mode!=='N'" class="mt-4">
        <transition-group name="fade" tag="div">

          <v-sheet v-for="(item, index) in followme_numbers" class="mb-4" :key="index" elevation="6" :color="item.active==='Y' ? undefined : 'grey lighten-2'">
            <div class="d-flex px-4 pt-4" v-if="forwarding_mode!=='C' && forwarding_mode!=='U'">
              <div class="flex-grow-1 flex-shrink-0">
                <v-text-field autofocus dense outlined label="Name *" v-model="item.name" :rules="rules.forward" :readonly="readOnlyName(index)"/>
              </div>
              <div v-if="forwarding_mode!=='C' && forwarding_mode!=='U' && sequence==='Weighted'">
                <v-text-field dense outlined label="Weight (%) *" type="number" v-model="item.weight" :rules="rules.weight" :error="sum_of_weights!==100" class="mx-2" style="max-width: 150px;" />
              </div>
              <div class="flex-shrink-0 flex-shrink-1">
                <SwitchYesNoInput v-model="item.active" label="Enabled" class="mx-2 my-1" hide-details :disabled="disableEnabledToggle(index)"/>
              </div>
            </div>
            <div class="px-4 pt-4">
              <v-row dense>
                <v-col cols="12" :sm="(forwarding_mode==='U' || forwarding_mode==='F') ? 5 : 8">
                  <v-text-field dense outlined label="Forward to *" v-model="item.redirect_number" :autofocus="forwarding_mode==='C' || forwarding_mode==='U'" :rules="rules.forward" :readonly="readOnlyRedirectNumber(index)"/>
                </v-col>
                <v-col cols="12" sm="5" v-if="forwarding_mode==='U' || forwarding_mode==='F'">
                  <v-text-field dense outlined label="SIP URI *" v-model="item.domain" :rules="rules.sipuri">
                    <template v-slot:prepend><span class="mt-1">@</span></template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" :sm="(forwarding_mode==='U' || forwarding_mode==='F') ? 2 : 4">
                  <v-text-field dense outlined label="Timeout (sec) *" type="number" v-model="item.timeout" :rules="rules.timeout" />
                </v-col>
              </v-row>
            </div>
            <div class="px-4 pb-4">
              <v-row dense>
                <v-col cols="12">
                  <SwitchYesNoInput v-model="item.keep_original_cli" label="Keep original CLI" dense hide-details />
                </v-col>
              </v-row>
              </div>
              <v-divider v-if="forwarding_mode==='Y' || forwarding_mode==='F'" />
              <div class="pa-2 text-right" v-if="forwarding_mode==='Y' || forwarding_mode==='F'" >
                <v-btn :disabled="disabledOrderUp(index)" @click="moveItemUp(index)" text icon ><v-icon v-text="'mdi-chevron-up'" /></v-btn>
                <v-btn :disabled="disabledOrderDown(index)" @click="moveItemDown(index)" text icon ><v-icon v-text="'mdi-chevron-down'" /></v-btn>
                <v-btn :disabled="disabledOrderDelete(index)" @click="deleteItem(index)" text color="error"><v-icon v-text="'mdi-delete'" />Delete</v-btn>
              </div>
            </v-sheet>
        </transition-group>
        </v-col>
      <v-col cols="12" v-if="forwarding_mode==='Y' || forwarding_mode==='F'" class="mb-4">
        <v-btn @click="addItem" text block><v-icon v-text="'mdi-plus'" /> Add new</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import SwitchYesNoInput from '@/components/pieces/SwitchYesNoInput';
export default {
  name: 'TrunkForwardSettingsForm',
  mixins: [trunkSettingsDataMixin],
  components: { SwitchYesNoInput },
  data: () => ({
    valid: false,
    forwarding_mode: null,
    sequence: 'Order',
    followme_numbers: [],
    initialFollowMeNumbers: [],
    initialFollowMeMode: [],
    rules: {
      timeout: [
        (v) => !!v || 'Required',
        (v) => /^\d+$/i.test(v) || 'Enter a valid number',
        (v) => parseInt(v)<=120 || 'Maximum timeout is 120 seconds',
        (v) => parseInt(v) !== 0 || 'Cannot be zero',
      ],
      weight: [
        (v) => !!v || 'Required',
        (v) => parseFloat(v)<=100 || '100% is max',
        (v) => parseFloat(v) !== 0 || 'Cannot be zero',
      ],
      required: [
        (v) => !!v || 'Required',
      ],
      forward: [
        (v) => !!v || 'Required',
        (v) => (v != undefined && v.length<=32) || 'Too long! Maximum 32 characters',
      ],
      sipuri: [
        (v) => !!v || 'SIP URI is required',
        //(v) => /^(?:(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])(\.(?!$)|$)){4}|(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}$)/i.test(v) || 'SIP URI should be a valid FQDN or IPv4 address', //this makes safari sad.
        (v) => /^((?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/i.test(v) || 'SIP URI should be a valid FQDN or IPv4 address',
      ]
    }
  }),
  methods: {
    readOnlyName(index){
      if(index == 0 && this.data.account_type == 'RING-GROUP'){
        return true;
      }else{
        return false;
      }
    },
    disableEnabledToggle(index){
      if(index == 0 && this.data.account_type == 'RING-GROUP'){
        return true;
      }else{
        return false;
      }
    },
    readOnlyRedirectNumber(index){
      if(index == 0 && this.data.account_type == 'RING-GROUP'){
        return true;
      }else{
        return false;
      }
    },
    disabledOrderUp(index){
      if(this.followme_numbers.length===1){
        return true;
      }else if(index === 0){
        return true;
      }else if(index == 1 && this.data.account_type == 'RING-GROUP'){
        return true;
      }else{
        return false;
      }
    },
    disabledOrderDown(index){
      if(this.followme_numbers.length===1){
        return true;
      }else if(index===(this.followme_numbers.length - 1)){
        return true;
      }else if(index == 0 && this.data.account_type == 'RING-GROUP'){
        return true;
      }else{
        return false;
      }
    },
    disabledOrderDelete(index){
      if(this.followme_numbers.length===1){
        return true;
      }else if(index === 0 && this.data.account_type == 'RING-GROUP'){
        return true;
      }
      return false;
    },

    addItem() {
      this.followme_numbers.push({
        i_account: this.followme.followme_info ? this.followme.followme_info.i_account : null,
        i_follow_me: null,
        i_follow_me_number: null,
        active: 'Y',
        redirect_number: '',
        domain: null,
        name: null,
        timeout: this.followme ? this.followme.default_followme_numbers_timeout : 30,
        keep_original_cld: 'N',
        keep_original_cli: 'Y',
        max_sim_calls: null,
        weight: 0,
        test: null
      })
    },
    deleteItem(index) {
      this.followme_numbers.splice(index, 1);
    },
    async moveItemUp(index) {
      var item = this.followme_numbers.splice(index, 1)[0];
      await this.$nextTick();
      this.followme_numbers.splice(index - 1, 0, item);
    },
    async moveItemDown(index) {
      var item = this.followme_numbers.splice(index, 1)[0];
      await this.$nextTick();
      this.followme_numbers.splice(index + 1, 0, item);
    },
    getEmitData() {
      let data = {
        forwarding_mode: this.forwarding_mode
      };
      if (data.forwarding_mode!=='N') {
        data.followme_numbers = [];
        this.followme_numbers.forEach((item, index) => item.i_follow_order = (index + 1) && data.followme_numbers.push(item));
      }
      if (data.forwarding_mode==='F' || data.forwarding_mode==='Y') data.sequence = this.sequence ? this.sequence : 'Order';
      return data;
    },

    updateForwardingMode() {
      if (this.forwarding_mode==='N'){
        this.followme_numbers = [];
        return;
      }

      let includeId = true;


      //if we are switching between advanced or simple we need to remove the ID. Else they need to remain.
      if((this.initialFollowMeMode == 'C' || this.initialFollowMeMode == 'U')
          && (this.forwarding_mode == 'F' || this.forwarding_mode == 'Y'))
      {
        includeId = false;
      }

      if((this.initialFollowMeMode == 'F' || this.initialFollowMeMode == 'Y')
          && (this.forwarding_mode == 'C' || this.forwarding_mode == 'U'))
      {
        includeId = false;
      }

      let total = 0;
      if (this.forwarding_mode==='N'){
        this.followme_numbers = [];
      }else if (this.forwarding_mode==='F' || this.forwarding_mode==='Y'){
        if(this.followme_numbers.length >= this.initialFollowMeNumbers.length){
          //more number added than initially loaded. No need to rework.
          total = this.followme_numbers.length;
        }else{
          total = this.initialFollowMeNumbers.length;
        }
      }else{
        total = 1;
      }

      //we now rework the numbers so we have replace any number from the original source.
      if(total != null) {
        let newFollowMeNumbers = [];
        for (let i = 0; i < total; i++) {
          if (this.followme_numbers[i]) {
            newFollowMeNumbers.push(this.followme_numbers[i]);
          } else if (this.initialFollowMeNumbers[(i - this.followme_numbers.length)]) {
            newFollowMeNumbers.push(this.initialFollowMeNumbers[(i - this.followme_numbers.length)]);
          } else {
            break;
          }
          if(!includeId){
            newFollowMeNumbers[(newFollowMeNumbers.length- 1)].i_follow_me_number = null;
          }
        }

        this.followme_numbers = newFollowMeNumbers;
      }

      if(this.followme_numbers.length == 0 && this.forwarding_mode!=='N'){
        this.addItem();
      }

    },
  },
  computed: {
    sum_of_weights() {
      let total = 0;
      this.followme_numbers.forEach(item => total = total + parseInt(item.weight));
      return total;
    }
  },
  watch: {
    valid(val) {
      if (val) {
        this.$emit('input', this.getEmitData());
      } else {
        this.$emit('input', null);
      }
    },
    sequence() {
      this.$emit('input', this.valid ? this.getEmitData() : null);
    },
    followme_numbers: {
      deep: true,
      handler() {
        this.$emit('input', this.valid ? this.getEmitData() : null);
      },
    }
  },
  props: {
    forwardingMode: {
      type: String,
      default: 'N'
    },
    value: {
      type: Object,
      default: null
    },
    followme: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      required:true
    },
  },
  created() {
    this.initialFollowMeNumbers = [];
    this.forwarding_mode = this.forwardingMode.toString();
    this.initialFollowMeMode = this.forwarding_mode;
    if (this.followme && this.followme.followme_numbers) {
      this.followme.followme_numbers.forEach(item => this.followme_numbers.push({ ...item }));
      this.initialFollowMeNumbers = this.followme_numbers;
    }
    this.sequence = this.followme && this.followme.followme_info ? this.followme.followme_info.sequence ? this.followme.followme_info.sequence : 'Order' : 'Order';
    if(this.followme_numbers.length == 0 && this.forwarding_mode!=='N'){
      this.addItem();
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>