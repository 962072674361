var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showFilters)?_c('v-card',{staticClass:"pa-2 mb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","clearable":"","dense":"","label":"Extension Number","disabled":_vm.isLoading},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateLocalFilter($event)}},model:{value:(_vm.localFilter.id),callback:function ($$v) {_vm.$set(_vm.localFilter, "id", $$v)},expression:"localFilter.id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"outlined":"","block":"","height":"40","loading":_vm.isLoading},on:{"click":_vm.updateLocalFilter}},[_vm._v("Search")])],1)],1)],1):_vm._e(),_c('PaginationCard',{attrs:{"page":_vm.filter.page,"data":_vm.data,"limit":_vm.filter.limit,"loading":_vm.loading || _vm.isLoading,"no-skeleton":!_vm.firstLoad},on:{"updateLimit":_vm.updateLimit,"updatePage":_vm.updatePage}},[_c('v-simple-table',[_c('thead',[_c('tr',[(_vm.trunkData.ringGroupDetails.sequence == 'Order')?_c('th',{staticClass:"text-center"},[_vm._v("Order")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v("Extension Number")]),_c('th',{staticClass:"text-center"},[_vm._v("Assigned to Phone Line")]),_c('th',{staticClass:"text-center"},[_vm._v("Ringing")]),_c('th',{staticClass:"text-center"},[_vm._v("Ignore Follow-me/Voicemail")]),_c('th',{staticClass:"text-center"},[_vm._v("Primary")]),_c('th',{staticClass:"text-center"})])]),_c('v-slide-x-transition',{attrs:{"tag":"tbody","hide-on-leave":"","group":""}},[(_vm.data.data && _vm.data.data.length === 0)?_c('tr',{key:"nodata"},[_c('td',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v(" No linked extensions ")])]):_vm._e(),_vm._l((_vm.data.data),function(item){return _c('tr',{key:item.id,class:{
                        /*'red darken-4':
                            $vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                        'red lighten-4':
                            !$vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',*/
                        'grey darken-3':
                            _vm.$vuetify.theme.dark && item.hunt_active === 'N',
                        'grey lighten-3':
                            !_vm.$vuetify.theme.dark && item.hunt_active === 'N',
                    }},[(_vm.trunkData.ringGroupDetails.sequence == 'Order')?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.hunt_order)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[(item.i_account)?_c('router-link',{staticClass:"font-weight-medium",attrs:{"to":{
                                name: 'TrunkSummary',
                                params: {
                                    customerId: _vm.customerData.i_customer,
                                    trunkId: item.i_account,
                                },
                            }}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.account_id)+" ")]),_c('td',{staticClass:"text-center"},[(item.hunt_active === 'N')?_c('span',[_vm._v("Disabled")]):_vm._e(),(item.hunt_active === 'Y' && item.hunt_delay != null && item.hunt_expire != null)?_c('span',[_vm._v("Wait for "+_vm._s(item.hunt_delay)+" seconds, then rings for "+_vm._s(item.hunt_expire)+" seconds")]):_vm._e(),(item.hunt_active === 'Y' && (item.hunt_delay == null && item.hunt_expire == null))?_c('span',[_vm._v("Permanently Enabled")]):_vm._e()]),_c('td',{staticClass:"text-center"},[(item.huntstop === 'Y')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(item.huntstop === 'N')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-cancel")]):_vm._e()],1),_c('td',{staticClass:"text-center"},[(item.primary_group === 'Y')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(item.primary_group === 'N')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-cancel")]):_vm._e()],1),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openUpdateExtensionDialog(item);}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"innerHTML":_vm._s('mdi-border-color')}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit Settings")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.openDeleteExtensionDialog(item);}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"innerHTML":_vm._s('mdi-link-off')}})],1)]}}],null,true)},[_c('span',[_vm._v("Unassign Extension")])])],1)])})],2)],1)],1),_c('UpdateRingGroupSettingsDialog',{attrs:{"data":_vm.interactingExtensionData,"trunk-data":_vm.trunkData},model:{value:(_vm.showUpdateExtension),callback:function ($$v) {_vm.showUpdateExtension=$$v},expression:"showUpdateExtension"}}),_c('RemoveRingGroupSettingsDialog',{attrs:{"data":_vm.interactingExtensionData,"trunk-data":_vm.trunkData},model:{value:(_vm.showDeleteExtension),callback:function ($$v) {_vm.showDeleteExtension=$$v},expression:"showDeleteExtension"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }