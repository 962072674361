<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" v-if="resellerData && resellerData.flagged_notes">
                <PageAlert
                    type="info"
                    icon="mdi-pin"
                    :button="$route.name!=='CustomerNotes'"
                    button-text="View Notes"
                    :button-to="{ name: 'CustomerNotes' }"
                >
                    {{resellerData.flagged_notes[0].note}}<br/>
                    <span style="font-size: 10pt;">{{resellerData.flagged_notes[0].firstname}} {{resellerData.flagged_notes[0].lastname}} added this note <LocalDateTime :value="resellerData.flagged_notes[0].created" relative />.</span>
                    <span v-if="resellerData.flagged_notes.length>1" class="text--disabled"><br/>Note: there are multiple pinned notes for this customer - only the most recent is shown above.</span>
                </PageAlert>
            </v-col>
            <v-col cols="12">
                <!-- content -->
                <router-view />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiMixin from '../../mixins/apiMixin';
import CustomerBlockStatusDialog from '../../components/dialogs/Customer/CustomerBlockStatusDialog';
import PageAlert from '../../components/core/PageAlert';
import LocalDateTime from '../../components/pieces/LocalDateTime';
import resellerDataMixin from "../../mixins/resellerDataMixin";
export default {
    name: 'WholesaleManagedParent',
    mixins: [apiMixin, resellerDataMixin],
    components: {
        LocalDateTime,
        PageAlert,
    },
    data: () => ({
        unblockDialog: false,
        unsuspendDialog: false,
        creditLimitDialog: false,
        //devicesPendingConfig:null,
    }),
    methods: {

     },

    beforeRouteUpdate(to, from, next) {
      if (to.params.customerId && to.params.customerId!==from.params.customerId) this.get(to.params.customerId, false,this.forcedCustomerMixinType);
      next();
    },
  /**
   * this will set the global customer data.
   * Note that if already loaded it used loaded data
   */
    watch: {
      $route(to, from) {
        this.get(this.resellerId, false,this.forcedCustomerMixinType);
      },
    },

  /**
   * Below taken from CustomerParent.
   *
   * This feature was added to allow us to target the updating of customer data within the application.
   * For example when deleting a call queue it hooks into this lister so we can update the call queue count in the side menu.
   * We cannot use a global refresh here as the process tried to refresh the deleted call queue and it failed to find it as it had been deleted.
   */
  mounted() {
    document.addEventListener('refreshRequestedParentWholesaler', this.update);
  },
  beforeDestroy() {
    document.removeEventListener('refreshRequestedParentWholesaler', this.update);
  },
  /**
   * We keep this create method in the parent because if it is in the mixin component it is called every time the component is used.
   * Hence we end up with many request from the get process.
   */
  created() {
    this.get(this.resellerId, false,this.forcedCustomerMixinType);
  },
};
</script>

<style scoped>
.device-warning-link{
  color:#000000;
}
</style>
