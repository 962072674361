<template>
    <v-slide-group
        v-if="items"
        v-model="selected"
        show-arrows
        center-active
        mandatory
        ref="slideGroup"
    >
        <v-slide-item
            v-for="item in items"
            :key="item.i_product"
            :value="item"
            v-slot="{ active, toggle }"
        >
            <v-hover
                    v-slot="{ hover }"
            >
                <v-card
                    :elevation="active ? 4 : hover ? 2 : 0"
                    :color="active ? selectedColor : hover ? hoverColor : undefined"
                    class="text-center"
                    :class="active ? 'ma-2 pa-5' : 'ma-3 pa-4'"
                    :width="parseInt(itemWidth) + (active ? 10 : 0)"
                    @click="toggle"
                    outlined
                    :height="parseInt(itemHeight) + (active ? 10 : 0)"
                >
                    <v-row no-gutters align="center" justify="center" class="fill-height">
                        <v-col v-if="dynamicProduct">
                          <h3 :class="((item.description == null || item.description == '') ? 'mt-3 ' : '') + 'mb-0'">{{item.title}}</h3>
                          <p class="text--disabled subtitle-2">{{item.description}}</p>
                          <p v-if="item.price_label!=null"><strong style="font-size:xx-large">{{item.price_label}}</strong>
                            <span v-if="item.price_sub_label != null"><br/>{{item.price_sub_label}}</span>
                          </p>
                        </v-col>
                        <v-col v-else>
                          <v-icon v-if="item.icon" v-html="item.icon" size="48" />
                          <h2 v-if="item.channels">{{item.channels}} Line<span v-if="item.channels>1">s</span></h2>
                          <h4 v-if="planTypeText && item.cost!=0">{{planTypeText}}</h4>
                          <p v-if="item.mobile_minutes && addOn">+ <b>{{item.mobile_minutes | thousandSeparator}}</b> mobile minutes</p>
                          <h1 v-if="item.cost" class="mt-4 mb-1"><span v-if="addOn">+ </span>${{item.cost_gst_incl | thousandSeparator}}</h1>
                          <p v-if="item.cost" class="mb-2">per month</p>
                          <p v-if="item.mobile_minutes && !addOn" class="mb-0 text--disabled" style="font-size: 14px;">(includes <b>{{item.mobile_minutes | thousandSeparator}}</b> mobile minutes)</p>
                          <h3 v-if="item.cost==0" class="my-4">{{item.title}}</h3>
                        </v-col>
                    </v-row>
                </v-card>
            </v-hover>
        </v-slide-item>

    </v-slide-group>
</template>

<script>
    export default {
        name: 'PlanSelectSlider',
        data: () => ({
            selected: null,

            //used for the slide scrolling fix
            lastScroll: 0,
            lastAdjustedScroll:0,
            lastDirection:0,
        }),
        watch: {
            value(val) {
                if (this.selected!==val) this.selected=val;
            },
            selected(val) {
                if (this.value!==val) this.$emit('input', val);
            }
        },
        props: {
            items: {
                default: null
            },
            value: {
                type: Object,
                default: null
            },
            selectedColor: {
                type: String,
                default: 'accent'
            },
            hoverColor: {
                type: String,
                default: 'background_accent'
            },
            itemHeight: {
                type: Number,
                default: 210
            },
            itemWidth: {
                type: Number,
                default: 150
            },
            addOn: {
                type: Boolean,
                default: false
            },
            planTypeText: {
                type: String,
                default: null
            },
            dynamicProduct: {
                type: Boolean,
              default:false
            }
        },
        filters: {
            thousandSeparator: function(value) {
                if (!value) value = 0;
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
        },
        created() {
            if (this.value) this.selected = this.value;
        },
      mounted() {
        /**
         * This section was added due to issues with the default slider/sldeshow shipped with vuetify.
         * Reasons for this is there was a case where the a slide that was half showing of the right side, would then move to the left side
         * and be half showing. It was never possible to see the whole product (only if selecting it and it would then center itself).
         *
         * The is a known issue. Which is even present on the vuetify demo page.
         * https://github.com/vuetifyjs/vuetify/issues/15916
         *
         */
        this.$watch(
            () => {
              return this.$refs.slideGroup._data.scrollOffset;
            },
            val => {
              //not that this process will run twice as we adjust the scrollOffSet within this method. Second loop there is no change.
              let dir = val > this.lastScroll ? 1 : -1;
              this.lastScroll = val;
              let workingWidth = this.itemWidth + 24; //plus margin.
              let index;
              if (dir > 0) {
                index = Math.floor(val / workingWidth);
              } else {
                index = Math.ceil(val / workingWidth);
              }
              let newScroll = index * workingWidth;
              if(newScroll != this.lastAdjustedScroll){
                this.$refs.slideGroup._data.scrollOffset = newScroll;
                this.lastAdjustedScroll = newScroll;
              }//else do nothing. As we want to see the slides on the edges. If we keep resetting with the adjusted value we can never get to some slides.

            }
        );
      }
    };
</script>
