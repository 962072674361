<template>
    <v-row dense>
        <v-col cols="12" sm="6" lg="4">
            <TrunkCard :data="data" :loading="loading" />
        </v-col>
      <v-col cols="12" sm="6" lg="4" v-if="showAddress">
            <AddressCard :data="data" :loading="loading" @changeAddress="changeAddress()"/>
        </v-col>
      <v-expand-transition>
        <v-col cols="12" sm="6" lg="4" v-if="data.account_type == 'RING-GROUP'">
          <RingGroupSettingsCard :data="data" :loading="loading" />
        </v-col>
      </v-expand-transition>
        <v-col cols="12" sm="6" lg="4">
            <TrunkProductsCard :data="data" :loading="loading"/>
        </v-col>
      <v-expand-transition>
        <v-col cols="12" sm="6" lg="4"  v-if="showAuth" >
            <TrunkAuthCard :data="data" :loading="loading" />
        </v-col>
      </v-expand-transition>
        <v-expand-transition>
            <v-col cols="12" sm="6" lg="4" v-if="showRegistration" >
                <TrunkRegistrationCard
                    :data="data"
                    :loading="loading"
                    v-if="data.bill_status !== 'C'"
                />
            </v-col>
        </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" sm="6" lg="4" v-if="data.account_type == 'HOSTED-EXTENSION'">
          <ExtensionContactDetailsCard :data="data" :loading="loading" />
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" sm="6" lg="4">
          <TrunkFax2MailCard :data="data" :loading="loading" v-if="data.account_type == 'FAX-TO-MAIL'"></TrunkFax2MailCard>
        </v-col>
      </v-expand-transition>
      <UpdateAddressDialog v-model="changeAddressDialog" :trunkId="data.i_account" :customerId="data.i_customer" typeSwitch="trunk"/>
    </v-row>
</template>

<script>
import TrunkCard from '@/components/cards/Trunk/TrunkCard';
import AddressCard from '@/components/cards/AddressCard';
import TrunkRegistrationCard from '@/components/cards/Trunk/TrunkRegistrationCard';
import TrunkAuthCard from '../../components/cards/Trunk/TrunkAuthCard';
import TrunkProductsCard from '../../components/cards/Trunk/TrunkProductsCard';
import UpdateAddressDialog from '../../components/dialogs/Addresses/UpdateAddressDialog'
import ExtensionContactDetailsCard from "../../components/cards/Extension/ExtensionContactDetailsCard";
import TrunkFax2MailCard from "../../components/cards/Trunk/TrunkFax2MailCard";
import RingGroupSettingsCard from "../../components/cards/RingGroup/RingGroupSettingsCard";
import GlobalFetchStoreMixin from "../../mixins/GlobalFetchStoreMixin";
import apiMixin from "../../mixins/apiMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
export default {
    name: 'TrunkSummary',
    mixins: [GlobalFetchStoreMixin],
    data: () => ({
        busy:false,
        dialogs: {
            newDID: false,
        },
        changeAddressDialog:false,
        extensionRingGroupsResponse:null
    }),
    components: {
      RingGroupSettingsCard,
        UpdateAddressDialog,
        TrunkProductsCard,
        TrunkAuthCard,
        TrunkRegistrationCard,
        AddressCard,
        TrunkCard,
        ExtensionContactDetailsCard,
        TrunkFax2MailCard
    },
    computed: {
        data() {
            if (
                this.$store.state.data.trunk &&
                this.$store.state.data.trunk.i_account ===
                    parseInt(this.$route.params.trunkId)
            ) {
                return this.$store.state.data.trunk;
            }
            return {};
        },
        loading() {
            return this.fetchStoreLoading || this.busy;
        },
        showAddress( ){
          return this.data.account_type != 'RING-GROUP';
        },
        isHostedExtension( ){
          return this.data.account_type == 'HOSTED-EXTENSION';
        },
      showAuth( ){
          if(this.data.account_type == null){
            return false;
          }else if(this.data.account_type == 'FAX-TO-MAIL' || this.data.account_type == 'RING-GROUP' || this.data.account_type == 'AUTO-ATTENDANT'){
            return false;
          }else {
            return true;
          }
      },
      showRegistration( ){
        if(this.data.i_account_role !== 1){
          return false;
        }else if(this.data.account_type == 'FAX-TO-MAIL' || this.data.account_type == 'RING-GROUP' || this.data.account_type == 'AUTO-ATTENDANT'){
          return false;
        }else {
          return true;
        }
      },
    },

  methods: {
    changeAddress( ) {
      this.changeAddressDialog = true;
    },
  },

};
</script>
