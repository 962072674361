<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        allow-overflow
        transition="scale-transition"
        offset-y
        max-width="580"
        min-width="290"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="inputValue"
                ref="date"
                :label="label"
                :append-icon="mdiCalendarClock"
                outlined
                :dense="dense"
                :clearable="clearable"
                :messages="dateTimeString!==inputValue ? dateTimeString : null"
                :hide-details="hideDetails"
                :hint="hint"
                :persistent-hint="persistentHint"
                v-bind="attrs"
                v-on="on"
                @click:clear="clear"
                @click:append="menu=true"
                @blur="updateInputString"
                :disabled="loading"
                :loading="loading"
                :rules="rules"
            ></v-text-field>
        </template>
                <v-sheet
                    v-if="$vuetify.breakpoint.xsOnly"
                    width="290px"
                >
                    <v-tabs
                        v-model="tab"
                        grow
                    >
                        <v-tab>
                            <v-icon left>mdi-calendar-outline</v-icon>Date
                        </v-tab>
                        <v-tab :disabled="!dateValue">
                            <v-icon left>mdi-clock-outline</v-icon>Time
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" v-if="menu">
                        <v-tab-item>
                            <v-date-picker
                                v-model="dateValue"
                                scrollable
                                show-current
                                @click:date="selectDate"
                                class="rounded-0"
                                header-color="secondary"
                            />
                        </v-tab-item>
                        <v-tab-item>
                            <v-time-picker
                                v-model="timeValue"
                                header-color="secondary"
                                @click:hour="selectHour"
                                @click:minute="selectMinutes"
                                @click:second="selectSeconds"
                                class="rounded-0"
                                format="24hr"
                                use-seconds
                                scrollable
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-sheet>
                <v-sheet>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-date-picker
                              v-if="$vuetify.breakpoint.smAndUp"
                              v-model="dateValue"
                              scrollable
                              show-current
                              @click:date="selectDate"
                              class="rounded-0"
                              header-color="secondary"
                      />
                      <v-time-picker
                              v-if="$vuetify.breakpoint.smAndUp"
                              v-model="timeValue"
                              header-color="secondary"
                              @click:hour="selectHour"
                              @click:minute="selectMinutes"
                              @click:second="selectSeconds"
                              class="rounded-0"
                              format="24hr"
                              use-seconds
                              scrollable
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="8" class="pl-6 py-3 text--disabled text-subtitle-2">
                      Local timezone: {{timeZone}}
                    </v-col>
                    <v-col cols="4" class="pa-2 text-right">
                      <v-btn color="primary" text @click="menu=false">OK</v-btn>
                    </v-col>
                  </v-row>
                </v-sheet>

    </v-menu>
</template>

<script>
import { mdiCalendarClock } from '@mdi/js';
const DateRegex = /^(((?:19|20)\d{2})(?:-(0[1-9]|1[0-2])(?:-(0[1-9]|[1-2][0-9]|3[0-1])(?: ((?:[0-1]\d|2[0-3]))(?::([0-5]\d)(?::([0-5]\d))?)?)?)?)?)$/i;
export default {
    name: 'DateTimePicker',
    data: () => ({
        dateTime: null,
        dateValue: null,
        timeZone: "Australia/Adelaide",
        timeValue: null,
        menu: false,
        inputValue: '',
        mdiCalendarClock: mdiCalendarClock,
        rules: [
            (v) =>
                !v ||
                DateRegex.test(v) ||
                'Enter date/time in YYYY-MM-DD HH:MM:SS',
        ],
        dirty: false,
        tab: 0
    }),
    props: {
        value: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Date/Time',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        persistentHint: {
            type: Boolean,
            default: false,
        },
        hint: {
            type: String,
            default: null,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        futureOnly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        update: function (value, utc) {
          let results =
                value ? value.match(DateRegex) : null;
            if (results) {
                let parsed = new Date();
                if (utc) {
                    parsed.setUTCFullYear(results[2]);
                    parsed.setUTCMonth(results[3] ? parseInt(results[3])-1 : 0);
                    parsed.setUTCDate(results[4] || 1);
                    parsed.setUTCHours(results[5] || 0);
                    parsed.setUTCMinutes(results[6] || 0);
                    parsed.setUTCSeconds(results[7] || 0);
                    this.dateValue = parsed.getFullYear() + '-' + ('0' + (parsed.getMonth() + 1)).slice(-2) + '-' + ('0' + parsed.getDate()).slice(-2);
                    this.timeValue = ('0' + parsed.getHours()).slice(-2) + ':' + ('0' + parsed.getMinutes()).slice(-2) + ':' + ('0' + parsed.getSeconds()).slice(-2);
                    this.inputValue = this.dateTimeString;
                } else {
                    parsed.setFullYear(results[2]);
                    parsed.setMonth(results[3] ? parseInt(results[3])-1 : 0);
                    parsed.setDate(results[4] || 1);
                    parsed.setHours(results[5] || 0);
                    parsed.setMinutes(results[6] || 0);
                    parsed.setSeconds(results[7] || 0);
                    this.dateValue = results[2] + '-' + (results[3] || '01') + '-' + (results[4] || '01');
                    this.timeValue = (results[5] || '00') + ':' + (results[6] || '00') + ':' + (results[7] || '00');
                }
                this.dateTime = parsed;
            } else {
                this.dateValue = null;
                this.timeValue = null;
                this.dateTime = null;
            }
        },
        clear: function () {
            this.dateValue = null;
            this.inputValue = '';
        },
        selectDate() {
            if (!this.timeValue) this.timeValue = '00:00:00';
            this.dateTime = new Date(this.dateValue + ' ' + this.timeValue);
            this.tab = 1;
            this.updateInputString();
        },
        selectHour(val) {
            this.dateTime.setHours(val);
            this.updateInputString();
        },
        selectMinutes(val) {
            this.dateTime.setMinutes(val);
            this.updateInputString();
        },
        selectSeconds(val) {
            this.dateTime.setSeconds(val);
            this.updateInputString();
        },
        blur() {
            if (this.dateTimeString) {
                this.updateInputString();
            }
        },
        updateInputString() {
            if (this.inputValue!==this.dateTimeString) this.inputValue = this.dateTimeString;
        }
    },
    computed: {
        dateTimeString() {
            return this.dateValue ? (this.dateValue + ' ' + this.timeValue) : null;
        },
        dateTimeUTCString() {
            return this.dateTime ? this.dateTime.toISOString().slice(0, 19).replace('T', ' ') : '';
        }
    },
    watch: {
        dateTime: function () {
            this.$emit('input', this.dateTimeUTCString);
        },
        value: function (value) {
            if (value!==this.dateTimeUTCString) {
                this.update(value, true);
            }
        },
        inputValue: function (value) {
            this.update(value, false);
        },
        menu(val) {
            if (!val) {
                //this.time = null;
                this.tab = 0;
            }
        }
    },
    created() {
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.required) this.rules.push((v) => !!v || 'Required');
        if (this.futureOnly)
            this.rules.push(
                (v) =>
                    (/^(\d{4}-(?:0[1-9]|1[0-2])-+(?:[0-2][1-9]|3[0-1]))$/i.test(
                        v
                    )
                        ? new Date(v).getDate() > new Date().getDate()
                        : true) || 'Must be in the future'
            );
        if (this.value) {
            this.update(this.value, true);
        }
    },
};
</script>

<style>
.v-time-picker-title__time * {
    font-size: 56px !important;
}
.v-time-picker-title * {
    height: 56px !important;
    margin-bottom: 0px !important;
}
</style>
