<template>
    <div>
        <v-sheet v-if="displayProductDetails" rounded class="pa-4" color="background_accent">
          <spinner-overlay v-if="busy" />
            <v-row no-gutters v-if="displayProductDetails.i_product != null">
                <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
                    <p class="text-h6 mb-1">{{displayProductDetails.name}}</p>
                    <p v-if="displayProductDetails.cost != null"><span class="text-subtitle-1">{{displayProductDetails.cost_gst_incl| currency}} per month </span><span class="text--disabled">including GST</span></p>
                    <p v-if="showCallRated">
                        <span>Includes outbound calls to 1800 numbers and other numbers hosted with Breeze Connect.</span>
                        All other calls are billed at standard rates.
                    </p>
                    <p v-if="displayProductDetails.url">For full details refer to the <a :href="displayProductDetails.url" target="_blank">Critical Information Summary</a>.</p>
                </v-col>
                <v-col cols="12" md="6">
                    <v-simple-table style="max-width: 600px;" dense v-if="!!displayProductDetails && showCallRated && rateSummaryDetails == null && !busy">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Calls to
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Local/national numbers
                                </td>
                                <td>
                                  <span v-if="displayProductDetails.local_national_numbers_label">{{displayProductDetails.local_national_numbers_label}}</span>
                                  <span v-else>9c per call</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Australian mobiles</td>
                                <td>
                                    <span v-if="displayProductDetails.mobile_minutes">{{displayProductDetails.mobile_minutes | thousandSeparator}} minutes included<br/><span style="font-size:11px;">then 14c per minute + 14c connection fee</span></span>
                                    <span v-else>14c per minute<br/><span style="font-size:11px;">plus 14c connection fee</span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>13/1300 numbers</td>
                                <td>
                                    <span v-if="displayProductDetails.numbers_13_1300_label">{{displayProductDetails.numbers_13_1300_label }}</span>
                                    <span v-else>28c per call</span>
                                </td>
                            </tr>
                            <tr>
                                <td>1800 numbers</td>
                                <td>Included</td>
                            </tr>
                            <tr>
                                <td>Breeze Connect numbers</td>
                                <td>Included</td>
                            </tr>
                            <tr>
                                <td>Satellite numbers (014x)</td>
                                <td>$3.00 per min</td>
                            </tr>
                            <tr>
                                <td>International calls</td>
                                <td>
                                    <span>Standard international rates apply<br/><span style="font-size:11px;">minimum charge 60 seconds</span></span>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                  <v-simple-table style="max-width: 600px;" dense v-if="!!displayProductDetails && showCallRated && rateSummaryDetails != null && !busy">
                    <thead>
                    <tr>
                      <th class="text-left">
                        Calls to
                      </th>
                      <th class="text-left">
                        Cost
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        Local/national numbers
                      </td>
                      <td>
                        {{rateSummaryDetails['australian-numbers']}}
                      </td>
                    </tr>
                    <tr>
                      <td>Australian mobiles</td>
                      <td>
                        {{rateSummaryDetails['australian-mobile']}}
                      </td>
                    </tr>
                    <tr>
                      <td>1300 numbers</td>
                      <td>
                        {{rateSummaryDetails['1300']}}
                      </td>
                    </tr>
                    <tr>
                      <td>1800 numbers</td>
                      <td>{{rateSummaryDetails['1800']}}</td>
                    </tr>
                    <tr>
                      <td>Satellite numbers (014x)</td>
                      <td>{{rateSummaryDetails['australian-satellite']}}</td>
                    </tr>
                    <tr>
                      <td>International calls</td>
                      <td>
                        {{rateSummaryDetails['international-rates']}}
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>

                </v-col>
            </v-row>
          <v-row no-gutters v-if="displayProductDetails.i_product == null">
            <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
              <p class="text-h6 mb-1">{{displayProductDetails.name}}</p>
              <p><span class="text-subtitle-1">{{displayProductDetails.cost_gst_incl | currency}} per month </span><span class="text--disabled">including GST</span></p>
              <p v-if="displayProductDetails.url">For full details refer to the <a :href="displayProductDetails.url" target="_blank">Critical Information Summary</a>.</p>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-if="displayProductDetails && displayProductDetails.i_product && displayProductOptionDetails" rounded class="mt-4 pa-4" color="background_accent">
          <v-row no-gutters align="center" style="font-size: 13px;">
            <v-col cols="12" md="6" class="pr-md-4">
              <p class="text-h6 mb-1">{{displayProductOptionDetails.title}}</p>
              <p><span class="text-subtitle-1">{{displayProductOptionDetails.cost_gst_incl | currency}} per month </span><span class="text--disabled">including GST</span></p>
              <p>{{displayProductOptionDetails.description}}</p>
              <ul v-if="displayProductOptionDetails.channel_count != null || displayProductOptionDetails.i_vd_plan != null">
                <li v-if="displayProductOptionDetails.channel_count != null">Channel Count: {{displayProductOptionDetails.channel_count}}</li>
                <li v-if="displayProductOptionDetails.i_vd_plan != null">Volume Discount: {{displayProductOptionDetails.volume_discount_description}}</li>
              </ul>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet
            color="background_accent"
            rounded
            class="pa-4 mt-4 text-right"
            v-if="!!displayProductDetails"
        >
          <span class="text-h5 text--disabled">Monthly Plan Fees </span>
          <span v-if="rateSummaryDetails == null" class="text-h3">{{parseFloat(displayProductDetails.cost_gst_incl) + parseFloat((!!displayProductOptionDetails ? displayProductOptionDetails.cost_gst_incl : 0)) | currency}}</span>
          <span v-else class="text-h3">{{parseFloat((!!displayProductOptionDetails ? displayProductOptionDetails.cost_gst_incl : 0)) | currency}}</span>
        </v-sheet>
        <v-sheet v-if="((!displayProductDetails || displayProductDetails.i_product == null) ) || displayProductDetails.i_product == null" rounded class="pa-4 mb-4" color="background_accent">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
              <p class="text-h6 mb-1">No plan selected</p>
              <p><span class="text-subtitle-1">$0.00 per month </span></p>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table style="max-width: 600px;" dense>
                <thead>
                <tr>
                  <th class="text-left">
                    Calls to
                  </th>
                  <th class="text-left">
                    Cost
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    All destinations
                  </td>
                  <td>Not available</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
                <strong class="font-weight-bold red--text">This Account will be unable to make/receive calls until a plan is selected.</strong>
            </v-col>
          </v-row>
        </v-sheet>
    </div>
</template>

<script>
import planDataMixin from '../../../mixins/planDataMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import SpinnerOverlay from "../Global/SpinnerOverlay";
export default {
    name: 'TrunkProductInfoSummary',
  components: {SpinnerOverlay},
  mixins: [GlobalHelperMixin],
    data: () => ({
        busy:false,
        displayProductDetails:null, //with a main or sub product.
        displayProductOptionDetails:null, //product addon used with products without a main product. EG HOSTED product has no main. Wholesale product have main but no addons.
        type: null,
        rateSummaryDetails:null,
        accountCustomerData:null,
    }),
    props: {
      /**
       * This is the plan data. It can be null if we are viewing the plan details.
       * IE if this component is used in the create or edit process then we need to pass these details. Else it pull the product based on the trunkData.i_account
       * Note that these details will match the logic from portaOne.
       * So is can include main, sub and addon products. The logic within in component to determine how to lay these out.
       */
      data: {
        type: Object,
        default: null,
      },
      products: {
        type: Array,
        required:true,
      },
      showCallRated:{
          type:Boolean,
        default:true,
      },
      trunkData: {
        type: Object,
        default: null,
      },
      customerData: {
        type: Object,
        default: null,
      },
    },
    methods: {
      manageRates( ){
        if(this.accountCustomerData.customer_class_sip_trunk_type=='managed-partner-customer' || this.accountCustomerData.customer_class_sip_trunk_type=='managed-customer') {
          this.getRateSummary();
        }else{
          this.rateSummaryDetails = null;
        }
      },
      async getCustomerData( ){
        this.busy = true;
        const response = await this.Api.send('get','customers/' + this.trunkData.i_customer + '?extended_info=1');
        if (response.success) {
          this.accountCustomerData = response.data;
        }
        this.busy = false;
      },
      async getRateSummary( ){
        this.busy = true;
        let params = {};
        if(this.data == null){
          params.main_i_product = this.trunkData.i_product;
          params.addon_products_i_product = [];
          this.trunkData.assigned_addons.forEach(function (item, index) {
            params.addon_products_i_product.push(item.i_product);
          });
        }else {
          params.main_i_product = this.data.mainProduct.i_product;
          params.addon_products_i_product = [this.data.subProduct.i_product];
        }
        let queryString = this.convertToQueryString(params);
        let response = await this.Api.send('get', 'sip-trunks/rate-summary-details/'+this.accountCustomerData.i_customer+'?'+queryString);
        if (response.success) {
          this.rateSummaryDetails = response.data;
        }
        this.busy = false;
      },
    },
    watch: {
      data: {
        handler(newValue, oldValue) {
          this.getCustomerData( );
        },
        deep: true
      },
      accountCustomerData: {
        handler(newValue, oldValue) {
          this.manageRates( );
        },
        deep: true
      }
    },
    created(){
      /**
       * Here we need to determine which product are needed for display. There are 2 main situation this component is in use
       * 1) When we are create a new trunk and selected products are passed.
       * 2) When we are reviewing the products for a trunk that already exists and the trunk data is passed.
       *
       * There are 2 main type of data arrangement we are catering for
       * 1) For hosted trunks there is no main product. that is behind the scenes. So we run of a subproduct and productOption.
       * 2) For Wholesale trunks there is a main product and they can have subProducts.
       *
       * NOTE SIP trunk use the TrunkPlanInfoSummary view at this time.
       *
       * Here we need to cater for each type of situation.
       *
       * Note that the format that the products are supplied here will help use determine how to handle the data.
       * We the top level products that are passed for hosted trunks are subProduct. The top level passed for wholesale products are mainProducts.
       */

      if(this.data && (this.data.subProduct || this.data.mainProduct)){
        //start with the main product if it exist. This should mean its a wholesale trunk.
        if(this.data.mainProduct){
          this.displayProductDetails = this.products.find(f => f.i_product == this.data.mainProduct.i_product);
          if (this.displayProductDetails && this.data.subProduct) {
            this.displayProductOptionDetails = this.displayProductDetails.options.find(f => f.i_product == this.data.subProduct.i_product);
          }
        }else if(this.data.subProduct){
          this.displayProductDetails = this.products.find(f => f.i_product == this.data.subProduct.i_product);
          if (this.displayProductDetails && this.data.productOption) {
            this.displayProductOptionDetails = this.displayProductDetails.options.find(f => f.i_product == this.data.productOption.i_product);
          }
        }
      }else if(!this.data && this.trunkData.i_account) {
        if (this.trunkData.isWholesaleByo || this.trunkData.isWholesaleManaged) {
          this.displayProductDetails = this.products.find(e => e.i_product == this.trunkData.i_product);
          //we now check the options. We only expect there to be one option at this time.
          //assert
          if(this.trunkData.assigned_addons.length > 1){
            throw "Unexpected value of addon for wholesale trunk.";
          }
          this.displayProductOptionDetails = this.displayProductDetails.options.find(e => e.i_product == this.trunkData.assigned_addons[0].i_product);

        } else {
          //here we loop over each of the addon product and check for match in top level product in the products list.
          //this will find the subProduct for us.
          for (let i = 0; i < this.trunkData.assigned_addons.length; i++) {
            this.displayProductDetails = this.products.find(e => e.i_product == this.trunkData.assigned_addons[i].i_product);
            if (this.displayProductDetails) {
              break;
            }
          }

          //if we find a product search options
          if (this.displayProductDetails && this.displayProductDetails.options) {
            //now search options. Note we can search the same addon list here. As the subProduct i_product will NOT exists in the displayProductDetails.options
            for (let i = 0; i < this.trunkData.assigned_addons.length; i++) {
              this.displayProductOptionDetails = this.displayProductDetails.options.find(e => e.i_product == this.trunkData.assigned_addons[i].i_product);
              if (this.displayProductOptionDetails) {
                break;
              }
            }
            if (!this.displayProductOptionDetails) {
              //use empty plans
              this.displayProductOptionDetails = this.displayProductDetails.options.find(e => e.i_product == null);
            }
          }
        }
      }

      //if we did not find a plan then we fill with the empty plans
      if (!this.displayProductDetails) {
        //no plans select. We use the 'empty' plans.
        this.displayProductDetails = this.products.find(e => e.i_product == null);
        if(this.displayProductDetails.options) {
          this.displayProductOptionDetails = this.displayProductDetails.options.find(e => e.i_product == null);
        }
      }

      if(this.customerData != null){
        this.accountCustomerData = this.customerData;
      }else{
        this.getCustomerData();
      }
    }
};
</script>
<style scoped>
    table > tbody > tr:hover {
        background: transparent !important;
    }
    table > tbody > tr > td {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
    table > thead > tr > th {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
</style>