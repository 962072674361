<template>
    <div>
        <v-row no-gutters>
            <v-col
                v-for="(item, key) in products"
                :key="key" :cols="$vuetify.breakpoint.xsOnly ? 12 : undefined"
            >
                <v-hover
                    v-slot="{ hover }"
                >
                    <v-card
                        v-ripple
                        outlined
                        :elevation="mainProduct && mainProduct.i_product===item.i_product ? 4 : hover ? 2 : 0"
                        :color="mainProduct && mainProduct.i_product===item.i_product ? highlightColor : hover ? 'background_accent' : undefined"
                        :height="($vuetify.breakpoint.xsOnly ? 90 : 150) + (mainProduct && mainProduct.i_product===item.i_product ? 10 : 0)"
                        class="px-2 text-center"
                        :class="mainProduct && mainProduct.i_product===item.i_product ? 'ma-2 py-5' : 'ma-3 py-4'"
                        @click="changeMainProduct(item)"
                    >
                        <v-icon v-html="item.icon" color="secondary" size="48" :class="$vuetify.breakpoint.xsOnly ? 'float-left ma-2' : 'mb-2'" />
                      <h3 class="mb-0">{{item.name}}</h3>
                      <p class="subtitle-2"><span v-if="item.description" class="text--disabled">{{item.description}}</span><br v-if="!hideMainCost && item.description"/><strong v-if="!hideMainCost">{{item.price_label}}</strong></p>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
          <v-expand-transition  v-for="(item, key) in products" :key="key">
            <PlanSelectSlider
                v-if="item.name=='PAYG' && mainProduct"
                v-model="subProduct"
                :items="item.options"
                plan-type-text="PAYG"
            />
            <PlanSelectSlider
                v-if="item.name=='PLUS' && mainProduct"
                v-model="subProduct"
                :items="item.options"
                plan-type-text="PLUS"
            />
            <PlanSelectSlider
                v-if="item.name=='PLUS' && subProduct"
                v-model="addonProduct"
                :items="item.options2"
                add-on
            />
            <v-row no-gutters v-if="item.name=='NFR' && mainProduct">
              <v-col
                  v-for="item in item.options"
                  :key="item.i_product"
              >
                <v-hover
                    v-slot="{ hover }"
                >
                  <v-card
                      :elevation="subProduct.i_product===item.i_product ? 4 : hover ? 2 : 0"
                      :color="subProduct.i_product===item.i_product  ? highlightColor : hover ? 'background_accent' : undefined"
                      class="text-center"
                      :height="subProduct.i_product===item.i_product  ? 220 : 210"
                      :class="subProduct.i_product===item.i_product  ? 'pa-5 ma-2' : 'pa-4 ma-3'"
                      @click="subProduct=item"
                      outlined
                  >
                    <v-row no-gutters align="center" justify="center" class="fill-height">
                      <v-col v-if="item.alternate_text">
                        <h3 v-if="item.alternate_text" class="my-4">{{item.alternate_text}}</h3>
                      </v-col>
                      <div v-else>
                        <h2>{{item.channels}} Line<span v-if="item.channels>1">s</span></h2>
                        <h3 class="mb-6">NFR</h3>
                        <h1 class="mb-1">${{item.cost}}</h1>
                        <p>per month</p>
                      </div>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-expand-transition>
    </div>
</template>

<script>
import PlanSelectSlider from '../Forms/PlanSelectSlider';
import planDataMixin from '../../../mixins/planDataMixin';
export default {
    name: 'TrunkPlanForm',
    mixins: [planDataMixin],
    components: { PlanSelectSlider },
    data: () => ({
        mainProduct: null,
        originalMainProduct: null,
        subProduct: null,
        originalSubProduct: null,
        addonProduct: null,
        originalAddonProduct: null,
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
        fees() {
            let fees = 0;
            if (this.subProduct || this.subProduct===0) {
                let product = this.planData.plans.find(e => e.value === this.subProduct);
                fees = fees + (product ? product.cost : 0);
                if (product && product.type===120 && this.addonProduct) {
                    let addon = this.planData.plans.find(e => e.value === this.addonProduct);
                    fees = fees + (addon ? addon.cost : 0);
                }
            }
            return fees;
        },
        dirty() {
            return this.mainProduct.i_product===(this.originalMainProduct && this.originalMainProduct.i_product) && (this.subProduct ? this.subProduct.i_product : null)===(this.originalSubProduct ? this.originalSubProduct.i_product : null) && (this.addonProduct ? this.addonProduct.i_product : null)===(this.originalAddonProduct ? this.originalAddonProduct.i_product : null);
        },
    },
    watch: {
        subProduct(value) {
            this.update();
        },
        addonProduct(value) {
            this.update();
        }
    },
    methods: {
        // findPlan(item) {
        //     let plan = this.planData.plans.find(f => f.value===item.i_product);
        //     if (plan) {
        //         if (!plan.addOn) {
        //             this.baseProduct = plan.type;
        //             this.originalBaseProduct = plan.type;
        //             this.mainProduct = plan.value;
        //             this.originalMainProduct = plan.value;
        //         } else {
        //             this.addonProduct = plan.value;
        //             this.originalAddonProduct = plan.value;
        //         }
        //     }
        // },
        changeMainProduct(value) {
            this.mainProduct = value;
            this.subProduct = this.originalMainProduct && this.originalMainProduct.i_product===value.i_product ? this.originalSubProduct : null;
            this.addonProduct = this.originalMainProduct && this.originalMainProduct.i_product===value.i_product ? this.originalAddonProduct : null;
        },
        update() {
            if (this.dirty) {
                this.$emit('input', null);
            } else {
                // this.$emit('input', {
                //     i_product: this.mainProduct,
                //     assigned_addons: this.subProduct ? (this.addonProduct ? [{i_product: this.subProduct},{i_product: this.addonProduct}] : [{i_product: this.subProduct}]) : null
                // });
                this.$emit('input', {
                  mainProduct: this.mainProduct,
                  subProduct: this.subProduct,
                  productOption: this.addonProduct,
                });
            }

        }
    },
    filters: {
        thousandSeparator: function(value) {
            if (!value) value = 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    created() {
      if(this.trunkData.i_account) {
        /**
         * Need to build the 3 levels from the planDetails
         */
        this.mainProduct = this.products.find(f => f.i_product == this.trunkData.i_product);
        var self = this;
        this.trunkData.assigned_addons.forEach(function (item, index) {
          self.subProduct = self.mainProduct.options.find(f => f.i_product == item.i_product);
          if (self.mainProduct.options2) {
            self.subProduct = self.mainProduct.options2.find(f => f.i_product == item.i_product);
          }
        });

        this.originalMainProduct = this.mainProduct;
        this.originalSubProduct = this.subProduct;
        this.originalAddonProduct = this.addonProduct;
      }
    },
    props: {
        assigned_addons: {
            type: Array,
            default: null
        },
        /**
         * Product available to select.
         */
        products: {
          type: Array,
          required:true,
        },
      /**
       * When editing we pass in the current product details.
       * Note the term 'plan' is old skool and is present as this component was cloned from TrunkPlanForm
       */
      trunkData: {
        type: Object,
        default: function() { return {}; },
      },
      hideMainCost:{
        type:Boolean,
        default: false
      }
    }
};
</script>