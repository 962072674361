<template>
    <v-form v-model="form.valid">
        <v-row dense>
            <v-col cols="12" md="5">
                <CreditCardNumberField
                    v-model="form.number"
                    outlined
                    :autofocus="autofocus"
                />
            </v-col>
            <v-col cols="4" md="3">
                <v-select
                    v-model="form.expirationMonth"
                    label="Expiry Month"
                    :items="months"
                    :error-messages="expiry_errors"
                    :rules="rules.required"
                    @change="changeExpiry"
                    outlined
                />
            </v-col>
            <v-col cols="4" md="2">
                <v-select
                    v-model="form.expirationYear"
                    label="Expiry Year"
                    :items="years"
                    :rules="rules.required"
                    @change="changeExpiry"
                    outlined
                />
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field
                    v-model="form.cvv"
                    label="CVV"
                    :rules="rules.cvv"
                    :counter="3"
                    :maxlength="3"
                    outlined
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="form.name"
                    label="Cardholder Name"
                    :rules="rules.name"
                    :counter="26"
                    :maxlength="26"
                    outlined
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import CreditCardNumberField from './CreditCardNumberField';
    import creditCardMixin from '../../mixins/creditCardMixin';
    export default {
        name: 'CreditCardDetailsForm',
        components: { CreditCardNumberField },
        mixins: [creditCardMixin],
        data: () => ({
            form: {
                valid: false,
                number: null,
                expirationMonth: null,
                expirationYear: null,
                cvv: null,
                name: null,
                postcode: null,
            },
            expiry_errors: null,
            rules: {
                required: [
                    v => !!v || 'Required'
                ],
                cvv: [
                    v => !!v || 'Required',
                    v => !/[^0-9]+/.test(v) || 'Numbers only',
                    v => !!v && v.length === 3 || 'Too short',
                ],
                name: [
                    v => !!v || 'Required',
                    v => !/[^0-9A-Za-z ]+/.test(v) || 'Invalid characters',
                    v => !!v && v.length > 1 || 'Too short',
                ],
            }
        }),
        methods: {
            changeExpiry() {
                // check expiry month if expiry is this year
                this.expiry_errors = (parseInt(this.form.expirationMonth) < parseInt(this.minCardMonth) && this.form.expirationYear === this.minCardYear) ? 'Expiry in the past' : null;
            },
            updateParent() {
                if (this.form.valid && !!this.form.number && !!this.form.cvv && this.form.cvv.length===3) {
                    this.$emit('input', {
                        number: this.form.number,
                        cvv: this.form.cvv,
                        exp_date: this.form.expirationYear + '-' + this.form.expirationMonth + '-' + new Date(this.form.expirationYear, this.form.expirationMonth, 0).getDate(),
                        name: this.form.name,
                        postcode: this.form.postcode
                    });
                } else {
                    this.$emit('input', null);
                }
            }
        },
        watch: {
            form: {
                deep: true,
                handler: function() {
                    this.updateParent();
                }
            }
        },
        props: {
            autofocus: {
                type: Boolean,
                default: false
            }
        }
    };
</script>