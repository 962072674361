<template>
  <div>
    <router-view />
    <FloatingButton v-if="resellerData.status != 'closed'" tooltip="New Payment" icon="mdi-currency-usd" @click="showNewPayment=true" :busy="resellerLoading" />
    <ProcessPaymentDialog v-model="showNewPayment" :data="resellerData" />
  </div>
</template>

<script>
    import FloatingButton from '@/components/pieces/FloatingButton';
    import ProcessPaymentDialog from '@/components/dialogs/Customer/ProcessPaymentDialog';
    import resellerDataMixin from "../../mixins/resellerDataMixin";
    export default {
      name: 'WholesaleManagedBilling',
      mixins: [resellerDataMixin],
      components: { ProcessPaymentDialog, FloatingButton },
      data: () => ({
          invoices: {},
          showNewPayment: false,
        }),
      beforeRouteUpdate(to, from, next){
        return (this.showNewPayment) ? next(false) : next();
      },
      beforeRouteLeave(to, from, next){
        return (this.showNewPayment) ? next(false) : next();
      }

    };
</script>