import apiMixin from './apiMixin';
import refreshListenerMixin from './refreshListenerMixin';
export default {
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
    }),
    computed: {
        customerLoading() {
            return this.$store.state.loading.customer;
        },
        /**
         * Returns the stored customer data if the stored customer data i_customer = the i_customer in the query string.
         * Else if the logged in use has a customer record it will check if the stored customer data matches the logged in user.
         * If so it is returned.
         * This is how we reuse views for multiple 'home' pages that use customer ui components and then customer pages that use customer ui components.
         * For example a byo and reseller have customer records and hence use the customer ui components for their home page.
         * Then a reseller can own customers and need to view their customer pages which use the same customer ui components.
         * Hence in this situation the i_customer value would be in the query string.
         *
         * Note there is a conflict when logged in a as manage wholesale user (reseller). Hence the resellerDataMixin was created to cater for this.
         * However the logic still exists in their mixin to support managed wholesale users.
         * @returns {null|*|{}}
         */
        customerData() {
            if (
                (this.$store.state.data.customer && (this.$store.state.data.customer.i_customer === parseInt(this.$route.params.customerId)))
                || (this.$store.state.user.user_type === 'wholesale-managed' && this.$store.state.data.customer && this.$store.state.data.customer.i_customer_type == 2 && this.$store.state.data.customer.i_customer == this.$store.state.user.customer.i_customer)
                || (this.$store.state.user.user_type === 'wholesale-byo' && this.$store.state.data.customer && this.$store.state.data.customer.i_customer_class == process.env.VUE_APP_BYO_WHOLESALE_I_CUSTOMER_CLASS && this.$store.state.data.customer.i_customer == this.$store.state.user.customer.i_customer)
            ) {
                return this.$store.state.data.customer;
            }
            return {};
        },
        /**
         * Return the customer id based on a few different checks.
         * The reason for these checks here is that the customer UI components have multiple use cases.
         * The main issue being they can be used to show the details of the logged in user (if matched to a customer record. EG Byo Users).
         * Or the customer UI components can be used to view customers owned or managed by the logged in user.
         * Hence this method to pull the customer id is built to cater for this so the components get an accurate UI.
         *
         * First it will return the customer id in the URL.
         * Then it will check if the current stores customer data matches the current logged in user. If so that customer id is return.
         * Else it will check if the logged in user has a linked customer record and return the i_customer for the record from the returned sessions values.
         *
         * @returns {null|number|*}
         */
        customerId() {
            if(!this.$route.params.customerId){
                let customerData = this.customerData;
                if(!customerData || !customerData.i_customer){ //note that this.customerData can return an empty object {}
                    //check to see if any customer data is loaded.
                    if(this.$store.state.data.customer){
                        //invalid
                        if(process.env.VUE_APP_DEBUG){
                            console.error('Failed to return customerId from customerDataMixin. 01');
                        }
                        return null;
                    }else{
                        //check to see if the logged in user is a customer
                        if(this.$store.state.user.user_type == 'wholesale-byo' || this.$store.state.user.user_type == 'wholesale-managed'){
                            //we return the id from the session
                            return this.$store.state.user.customer.i_customer;
                        }else{
                            //invalid
                            if(process.env.VUE_APP_DEBUG){
                                console.error('Failed to return customerId from customerDataMixin. 02');
                            }
                            return null;
                        }
                    }
                }else{
                    return customerData.i_customer;
                }
            }else{
                return parseInt(this.$route.params.customerId);
            }
        },
        devicesPendingConfig( ){
            return this.customerData.devicesPendingConfig;
        },
        forcedCustomerMixinType( ){
            return this.$store.state.data.forcedCustomerMixinType??null;
        },
        customerLabel( ){
            if(this.customerData.i_customer_type == 2){
                return 'Wholesale User';
            }else{
                return 'Customer'
            }
        },
        byoCustomerClass( ){
            return process.env.VUE_APP_BYO_WHOLESALE_I_CUSTOMER_CLASS;
        }
    },
    methods: {
        /**
         * Retrieves the customer data. Either by making a call to the API or returning what is stored in storage.
         *
         * @param id i_customer
         * @param force if set to true will force the process to call the data from the API.
         * @param type what type of customer either
         * 'customer'
         * 'reseller'
         * 'any': either or of the above.
         * @returns {Promise<void>}
         */
        async get(id, force, type) {
            if(!type){
                console.error('Missing type value calling get on CustomerDataMixin.');
                // if(process.env.VUE_APP_DEBUG) {
                //     let msg = 'Mixing included in parent ('+this.$parent.$options._componentTag+'/'+this.$parent.$options.name+')';
                //     console.log(msg);
                //     if(this.detail != null){
                //         console.log(this.detail);
                //     }
                // }
                this.updateFailed(true);
            }
            if(!id){
                // let msg = 'Mixing included in parent ('+this.$parent.$options._componentTag+'/'+this.$parent.$options.name+')';
                // console.log(msg);
                //console.error('ID not passed to customerDataMixin get method.');
                throw 'ID not passed to customerDataMixin get method.';
            }
            if (
                force ||
                !this.customerData ||
                this.customerData.i_customer !== parseInt(id)
            ) {
                //At this point we are forcing a pull. Either by force toggle. There is not data already stored. Or the passed if does not match stored id.

                if(this.customerLoading){
                    /*
                    If we are already requesting a customer we do not need to run this process again..
                    We need this as every instance on customermixin then has an instance of refreshMixin.
                    Meaning every time a refresh event is broadcast it triggers an update on each instance of the customerMixin in the site.
                    Really it might have been better to only have 1 instance of customerMixin in the customer parent.
                    Alas this is not how the site is atm. Note the refreshMixin was added to the customerMixin at a later date to solve other issues.
                    So there is some history there.
                     */
                    return;
                }

                this.$store.commit('loading', { name: 'customer', data: true });
                const response = await this.Api.send('get','customers/' + id + '?extended_info=1');

                if (response.success) {

                    let customerData = response.data;
                    if(type == 'customer'){
                        if(response.data.i_customer_type != 1){
                            //redirect to wholesale user summary page.
                            this.updateFailed(true,{name: 'AdminWholesaleHome',params:{customerId:response.data.i_customer}});
                            return;
                        };
                    }else if(type == 'reseller'){
                        if(response.data.i_customer_type != 2){
                            //redirect to the customer summary page.
                            this.updateFailed(true,{name: 'CustomerSummary',params:{customerId:response.data.i_customer}});
                            return;
                        };
                    }else if(type != 'any'){
                        this.updateFailed(true,response.data);
                        return;
                    }

                    if(customerData.customer_class_tag=="hosted-extension"){
                        //check for any devices that need a fresh config file.
                        customerData.devicesPendingConfig = await this.getDevicesNeededNewConfig(customerData.i_customer);
                    }

                    this.$store.commit('data', {
                        name: 'customer',
                        data: customerData,
                    });

                    this.$store.commit('loading', {
                        name: 'customer',
                        data: false,
                    });
                } else {
                    this.updateFailed(response.error, response.errorDetail);
                }
            }
            //if (this.$store.state.user.user_type === 'admin' && this.customerData.i_rep){
            //All users accessing the customer should have access to their parent representative.
            await this.getPartner(this.customerData.i_rep);
            //}
            if (this.$store.state.user.user_type === 'admin' && this.customerData.i_parent){
                await this.getReseller(this.customerData.i_parent);
            }

        },

        async getReseller(id) {
            if (
                this.$store.state.data.reseller &&
                this.$store.state.data.reseller.i_customer === parseInt(id)
            ) {
                this.$store.commit('loading', {
                    name: 'customer',
                    data: false,
                });
            } else {
                this.$store.commit('loading', { name: 'reseller', data: true });
                const response = await this.Api.send('get','customers/' + id);
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'reseller',
                        data: response.data,
                    });
                    this.$store.commit('loading', {
                        name: 'reseller',
                        data: false,
                    });
                }
            }
        },
        async getPartner(id) {
            if (this.customerData.i_rep &&
                (!this.$store.state.data.partner ||
                    this.$store.state.data.partner.i_rep !== id)
            ) {
                this.$store.commit('loading', { name: 'partner', data: true });
                const response = await this.Api.send('get','partners/' + id);
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'partner',
                        data: response.data,
                    });
                }
                this.$store.commit('loading', { name: 'partner', data: false });
            }
        },
        async getDevicesNeededNewConfig(i_customer){
            const response = await this.Api.send('get','customers/' + i_customer + '/voip-devices-pending-config');
            if (response.success) {
                return response.data;
            }else{
                return null;
            }
        },

        update() {
            this.get(this.$route.params.customerId, true, this.forcedCustomerMixinType);
        },
        updateFailed(clear, routeData) {
            if(clear == null){
                clear = false;
            }
            let type = this.forcedCustomerMixinType;
            this.$store.commit('loading', { name: 'customer', data: false });
            if(clear){
                this.$store.commit('data', {
                    name: 'customer',
                    data: null,
                });
            }
            if(routeData != null){
                this.$router.push(routeData);
            }else {
                if (type == 'customer') {
                    if (this.$route.name != 'CustomerList') {
                        this.$router.push({name: 'CustomerList'});
                    }
                } else {
                    if (this.$route.name != 'WholesalesList') {
                        this.$router.push({name: 'WholesalesList'});
                    }
                }
            }
        },
    },
    // We we run this on create this process to update runs for each file the mixin is included in. It runs too many times.
    // It has since been moved to the WholesaleParent and CustomerParent components.
    // created() {
    //     console.log('cust creatred');
    //     this.get(this.$route.params.customerId, false,this.forcedCustomerMixinType);
    // },

};