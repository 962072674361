var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[(_vm.customerData && _vm.customerData.flagged_notes)?_c('v-col',{attrs:{"cols":"12"}},[_c('PageAlert',{attrs:{"type":"info","icon":"mdi-pin","button":_vm.$route.name!=='CustomerNotes',"button-text":"View Notes","button-to":{ name: 'CustomerNotes' }}},[_vm._v(" "+_vm._s(_vm.customerData.flagged_notes[0].note)),_c('br'),_c('span',{staticStyle:{"font-size":"10pt"}},[_vm._v(_vm._s(_vm.customerData.flagged_notes[0].firstname)+" "+_vm._s(_vm.customerData.flagged_notes[0].lastname)+" added this note "),_c('LocalDateTime',{attrs:{"value":_vm.customerData.flagged_notes[0].created,"relative":""}}),_vm._v(".")],1),(_vm.customerData.flagged_notes.length>1)?_c('span',{staticClass:"text--disabled"},[_c('br'),_vm._v("Note: there are multiple pinned notes for this customer - only the most recent is shown above.")]):_vm._e()])],1):_vm._e(),(_vm.customerData && _vm.devicesPendingConfig && _vm.devicesPendingConfig.total_records > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('PageAlert',{attrs:{"type":"warning","icon":"mdi-deskphone","button":_vm.$route.name!=='CustomerPendingVoipDevices',"button-text":"View Devices","button-to":{ name: 'CustomerPendingVoipDevices' }}},[(_vm.devicesPendingConfig.total_records < 4)?_c('span',[_vm._v(" The following device(s) need to be reprovisioned in order for changes to take effect on the Device. This can be achieved by rebooting the Device. Devices: "),(this.$route.name != 'VoipDevice' || this.$route.params.customerId != _vm.customerData.i_customer || this.$route.params.trunkId != _vm.devicesPendingConfig.results[0].i_account || this.$route.params.voipDeviceId != _vm.devicesPendingConfig.results[0].id)?_c('router-link',{class:'device-warning-link',attrs:{"to":{
                                name: 'VoipDevice',
                                params: {
                                    customerId: _vm.customerData.i_customer,
                                    trunkId: _vm.devicesPendingConfig.results[0].i_account,
                                    voipDeviceId:_vm.devicesPendingConfig.results[0].id,
                                }}}},[_vm._v(_vm._s(_vm.devicesPendingConfig.results[0].label)+"/"+_vm._s(_vm.devicesPendingConfig.results[0].mac_address)+" (ext:"+_vm._s(_vm.devicesPendingConfig.results[0].label)+")")]):_c('span',[_vm._v(_vm._s(_vm.devicesPendingConfig.results[0].label)+"/"+_vm._s(_vm.devicesPendingConfig.results[0].mac_address)+" (ext:"+_vm._s(_vm.devicesPendingConfig.results[0].label)+")")]),(_vm.devicesPendingConfig.results[1] && (this.$route.name != 'VoipDevice' || this.$route.params.customerId != _vm.customerData.i_customer || this.$route.params.trunkId != _vm.devicesPendingConfig.results[1].i_account || this.$route.params.voipDeviceId != _vm.devicesPendingConfig.results[1].id))?_c('span',[_vm._v(", "),_c('router-link',{class:'device-warning-link',attrs:{"to":{
                                name: 'VoipDevice',
                                params: {
                                    customerId: _vm.customerData.i_customer,
                                    trunkId: _vm.devicesPendingConfig.results[1].i_account,
                                    voipDeviceId:_vm.devicesPendingConfig.results[1].id,
                                }}}},[_vm._v(_vm._s(_vm.devicesPendingConfig.results[1].label)+"/"+_vm._s(_vm.devicesPendingConfig.results[1].mac_address)+" (ext:"+_vm._s(_vm.devicesPendingConfig.results[1].label)+")")])],1):(_vm.devicesPendingConfig.results[1])?_c('span',[_vm._v(", "+_vm._s(_vm.devicesPendingConfig.results[1].label)+"/"+_vm._s(_vm.devicesPendingConfig.results[1].mac_address)+" (ext:"+_vm._s(_vm.devicesPendingConfig.results[1].label)+")")]):_vm._e(),(_vm.devicesPendingConfig.results[2] && (this.$route.name != 'VoipDevice' || this.$route.params.customerId != _vm.customerData.i_customer || this.$route.params.trunkId != _vm.devicesPendingConfig.results[2].i_account || this.$route.params.voipDeviceId != _vm.devicesPendingConfig.results[2].id))?_c('span',[_vm._v(", "),_c('router-link',{class:'device-warning-link',attrs:{"to":{
                                name: 'VoipDevice',
                                params: {
                                    customerId: _vm.customerData.i_customer,
                                    trunkId: _vm.devicesPendingConfig.results[2].i_account,
                                    voipDeviceId:_vm.devicesPendingConfig.results[2].id,
                                }}}},[_vm._v(_vm._s(_vm.devicesPendingConfig.results[2].label)+"/"+_vm._s(_vm.devicesPendingConfig.results[2].mac_address)+" (ext:"+_vm._s(_vm.devicesPendingConfig.results[2].label)+")")])],1):(_vm.devicesPendingConfig.results[2])?_c('span',[_vm._v(", "+_vm._s(_vm.devicesPendingConfig.results[2].label)+"/"+_vm._s(_vm.devicesPendingConfig.results[2].mac_address)+" (ext:"+_vm._s(_vm.devicesPendingConfig.results[2].label)+")")]):_vm._e(),_vm._v(". ")],1):_c('span',[_vm._v(" There are "+_vm._s(_vm.devicesPendingConfig.total_records)+" devices that need to be reprovisioned in order for changes to take effect on the Device. This can be achieved by rebooting the Device. ")])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('router-view'),_c('CustomerBlockStatusDialog',{attrs:{"data":_vm.customerData},model:{value:(_vm.unblockDialog),callback:function ($$v) {_vm.unblockDialog=$$v},expression:"unblockDialog"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }