<template>
  <div>
    <v-row dense v-if="trunkData">
        <v-col cols="12">
          <AutoAttendantAccountAutoAttendantListCard
              :trunkData="trunkData"
              :loading="isBusy"
              @update="updateFilter"
              :filter="filter"
              show-filters
          />
        </v-col>
      <AutoAttendantCreateDialog :loading="isBusy" :trunkData="trunkData" v-model="showNewAutoAttendant"/>
      <FloatingButton v-if="customerData && customerData.status != 'closed'" tooltip="Add Auto Attendant" icon="mdi-plus" @click="showNewAutoAttendant=true" :loading="isBusy" />
    </v-row>
  </div>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import trunkDataMixin from "../../mixins/trunkDataMixin";
import FloatingButton from '@/components/pieces/FloatingButton';
import RingGroupExtensionListCard from "../../components/cards/RingGroup/RingGroupExtensionListCard";
import AutoAttendantAccountAutoAttendantListCard
  from "../../components/cards/AutoAttendant/AutoAttendantAccountAutoAttendantListCard";
import AutoAttendantCreateDialog from "../../components/dialogs/AutoAttendants/AutoAttendantCreateDialog";
export default {
  name: 'AutoAttendantAccountAutoAttendantList',
  mixins: [filterMixin, customerDataMixin, trunkDataMixin],
  components: {
    AutoAttendantCreateDialog,
    AutoAttendantAccountAutoAttendantListCard,
    FloatingButton,
  },
  data: () => ({
    filter: {
      limit: 10,
      page: 1,
      id: null,
      active: 'Y',
    },
    showNewAutoAttendant: false,
    extensionData: null,
  }),
  methods: {
  },
  computed: {
    isBusy: function( ){
      return this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
    },
  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'hosted-extension'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if (value != undefined && value != 'hosted-extension') {
        this.$router.push({name: 'CustomerSummary'});
      }
    }
  },

};
</script>
