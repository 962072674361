<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        title="Billing Details"
    >
        <v-row dense>
            <v-col cols="4">
                <span class="font-weight-bold">Billing email</span>

            </v-col>
            <v-col cols="8">
                {{ data.email }}<CopyToClipboard :value="data.email" small right />
            </v-col>
        </v-row>
        <v-row dense v-if="data.bcc">
            <v-col cols="5">
                <span class="font-weight-bold">BCC email</span>
            </v-col>
            <v-col cols="7">
                {{ data.bcc }}
            </v-col>
        </v-row>
      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Invoice Type</span>
        </v-col>
        <v-col cols="7">
          <span v-if="data.invoice_type_tag == 'summary'">Summary</span>
          <span v-if="data.invoice_type_tag == 'full'">Itemised</span>
        </v-col>
      </v-row>
        <v-row dense>
            <v-col cols="12">
                <span class="text--disabled">PDF bills will be addressed as follows:</span>
                <v-sheet rounded color="background_accent" class="pa-4">
                    <div v-if="data.firstname && data.firstname != null">
                      <span v-if="data.salutation">{{ data.salutation | upper }} </span>{{ data.firstname | upper }} <span v-if="data.midinit">{{data.midinit | upper}} </span>{{ data.lastname | upper }}<br/>
                      <span v-if="data.companyname">{{data.companyname | upper}}<br/></span>
                      {{ data.baddr1 | upper }}<br/>
                      <span v-if="data.baddr2">{{data.baddr2 | upper}}<br/></span>
                      {{ data.city | upper }} <span v-if="data.state">{{ addressData.states.find((e) => e.text===data.state).value }} </span>{{ data.zip }}
                    </div>
                    <div v-else>
                      *Please update customer address record.
                    </div>
                </v-sheet>
            </v-col>
        </v-row>


        <template v-slot:actions>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn
                        text
                        :disabled="loading || data.status == 'closed'"
                        v-on="on"
                        v-on:click="showUpdateDialog = true"
                    >
                        <v-icon left>mdi-pencil</v-icon> Invoice Type
                    </v-btn>
                </template>
                <span>Change Billing Details</span>
            </v-tooltip>
        </template>
      <EditBillingDetailsDialog v-model="showUpdateDialog" :customerData="data"/>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import addressDataMixin from '../../../mixins/addressDataMixin';
import CopyToClipboard from '../../pieces/CopyToClipboard';
import EditBillingDetailsDialog from "../../dialogs/Customer/EditBillingDetailsDialog";
export default {
    name: 'CustomerBillingDetailCard',
    mixins: [addressDataMixin],
    components: {EditBillingDetailsDialog, CopyToClipboard, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showUpdateDialog: false,
        };
    },
    filters: {
        upper(value) {
            return !!value ? value.toString().toUpperCase() : '';
        },
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
