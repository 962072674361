 <template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" v-if="customerData && customerData.flagged_notes">
                <PageAlert
                    type="info"
                    icon="mdi-pin"
                    :button="$route.name!=='CustomerNotes'"
                    button-text="View Notes"
                    :button-to="{ name: 'CustomerNotes' }"
                >
                    {{customerData.flagged_notes[0].note}}<br/>
                    <span style="font-size: 10pt;">{{customerData.flagged_notes[0].firstname}} {{customerData.flagged_notes[0].lastname}} added this note <LocalDateTime :value="customerData.flagged_notes[0].created" relative />.</span>
                    <span v-if="customerData.flagged_notes.length>1" class="text--disabled"><br/>Note: there are multiple pinned notes for this customer - only the most recent is shown above.</span>
                </PageAlert>
            </v-col>
          <v-col cols="12" v-if="customerData && devicesPendingConfig && devicesPendingConfig.total_records > 0">
            <PageAlert
                type="warning"
                icon="mdi-deskphone"
                :button="$route.name!=='CustomerPendingVoipDevices'"
                button-text="View Devices"
                :button-to="{ name: 'CustomerPendingVoipDevices' }"
            >
                <span v-if="devicesPendingConfig.total_records < 4">
                  The following device(s) need to be reprovisioned in order for changes to take effect on the Device. This can be achieved by rebooting the Device.
                  Devices: <router-link :class="'device-warning-link'" v-if="this.$route.name != 'VoipDevice' || this.$route.params.customerId != customerData.i_customer || this.$route.params.trunkId != devicesPendingConfig.results[0].i_account || this.$route.params.voipDeviceId != devicesPendingConfig.results[0].id" :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: devicesPendingConfig.results[0].i_account,
                                        voipDeviceId:devicesPendingConfig.results[0].id,
                                    }}">{{devicesPendingConfig.results[0].label}}/{{devicesPendingConfig.results[0].mac_address}} (ext:{{devicesPendingConfig.results[0].label}})</router-link>
                  <span v-else>{{devicesPendingConfig.results[0].label}}/{{devicesPendingConfig.results[0].mac_address}} (ext:{{devicesPendingConfig.results[0].label}})</span>
                  <span v-if="devicesPendingConfig.results[1] && (this.$route.name != 'VoipDevice' || this.$route.params.customerId != customerData.i_customer || this.$route.params.trunkId != devicesPendingConfig.results[1].i_account || this.$route.params.voipDeviceId != devicesPendingConfig.results[1].id)">, <router-link :class="'device-warning-link'" :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: devicesPendingConfig.results[1].i_account,
                                        voipDeviceId:devicesPendingConfig.results[1].id,
                                    }}">{{devicesPendingConfig.results[1].label}}/{{devicesPendingConfig.results[1].mac_address}} (ext:{{devicesPendingConfig.results[1].label}})</router-link></span>
                  <span v-else-if="devicesPendingConfig.results[1]">, {{devicesPendingConfig.results[1].label}}/{{devicesPendingConfig.results[1].mac_address}} (ext:{{devicesPendingConfig.results[1].label}})</span>
                  <span v-if="devicesPendingConfig.results[2] && (this.$route.name != 'VoipDevice' || this.$route.params.customerId != customerData.i_customer || this.$route.params.trunkId != devicesPendingConfig.results[2].i_account || this.$route.params.voipDeviceId != devicesPendingConfig.results[2].id)">, <router-link :class="'device-warning-link'" :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: devicesPendingConfig.results[2].i_account,
                                        voipDeviceId:devicesPendingConfig.results[2].id,
                                    }}">{{devicesPendingConfig.results[2].label}}/{{devicesPendingConfig.results[2].mac_address}} (ext:{{devicesPendingConfig.results[2].label}})</router-link></span>
                  <span v-else-if="devicesPendingConfig.results[2]">, {{devicesPendingConfig.results[2].label}}/{{devicesPendingConfig.results[2].mac_address}} (ext:{{devicesPendingConfig.results[2].label}})</span>.
                </span>
                <span v-else>
                  There are {{devicesPendingConfig.total_records}} devices that need to be reprovisioned in order for changes to take effect on the Device. This can be achieved by rebooting the Device.
                </span>
            </PageAlert>
          </v-col>
            <v-col cols="12">
                <!-- content -->
                <router-view />
                <!-- dialogs -->
                <CustomerBlockStatusDialog v-model="unblockDialog" :data="customerData" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import customerDataMixin from '../../mixins/customerDataMixin';
import apiMixin from '../../mixins/apiMixin';
import CustomerBlockStatusDialog from '../../components/dialogs/Customer/CustomerBlockStatusDialog';
import PageAlert from '../../components/core/PageAlert';
import LocalDateTime from '../../components/pieces/LocalDateTime';
export default {
    name: 'CustomerParent',
    mixins: [apiMixin, customerDataMixin],
    components: {
        LocalDateTime,
        PageAlert,
        CustomerBlockStatusDialog,
    },
    data: () => ({
        unblockDialog: false,
        unsuspendDialog: false,
        creditLimitDialog: false,
        //devicesPendingConfig:null,
    }),
    methods: {

     },

  /**
   *
   * @param to
   * @param from
   * @param next
   * I'm not sure why this process is for.
   * If a new URL is submitted with a differennt ID then the page is reloaded.
   * If the content of a customer is update the page is refreshed using the refreshmixing feature.
   * This seems to only cause the customer data to load more times than it should.
   * I am commenting it out for now until I can discoer its use case.
   */
    // beforeRouteUpdate(to, from, next) {
    //   if (to.params.customerId && to.params.customerId!==from.params.customerId) this.get(to.params.customerId);
    //   next();
    // },
  /**
   * this will set the global customer data.
   * Note that if already loaded it used loaded data
   */
    watch: {
      $route(to, from) {
        this.get(this.customerId, null,'customer');
      },
    },
    // //Note beforeRouteUpdate seems to be inconsistant.
    // beforeRouteUpdate(to, from, next) {
    //     this.get(to.params.customerId);
    //     next();
    // },

  /**
   * This feature was added to allow us to target the updating of customer data within the application.
   * For example when deleting a call queue it hooks into this lister so we can update the call queue count in the side menu.
   * We cannot use a global refresh here as the process tried to refresh the deleted call queue and it failed to find it as it had been deleted.
   */
  mounted() {
      document.addEventListener('refreshRequestedParentCustomer', this.update);
    },
  beforeDestroy() {
    document.removeEventListener('refreshRequestedParentCustomer', this.update);
  },
  /**
   * We keep this create method in the parent because if it is in the mixin component it is called every time the component is used.
   * Hence we end up with many request from the get process.
   */
  created() {
    this.get(this.customerId, false,this.forcedCustomerMixinType);
  },
};
</script>

<style scoped>
.device-warning-link{
  color:#000000;
}
</style>
