<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
            <DIDListCard
                :cust-id="customerData.i_customer"
                :loading="customerLoading"
                :filter="filter"
                @update="updateFilter"
                show-filters
                show-tools
                @addButtonTriggered="showNewDIDs=true"
            />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed' && ($store.state.user.isAdmin || $store.state.user.isPartner || $store.state.user.isByoWholesale)" tooltip="Add New DIDs" icon="mdi-phone-plus" @click="showNewDIDs=true" :loading="customerLoading" />
      <AddDIDDialog v-if="customerData && customerData.i_customer" v-model="showNewDIDs" :customerData="customerData" processTypeSwitch="customer" :validOptions="addDidOptions"/>
    </v-row>
</template>
<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialog from "../../components/dialogs/DID/AddDIDDialog";
export default {
    name: 'CustomerDIDs',
    mixins: [filterMixin, customerDataMixin],
    components: {
      AddDIDDialog,
      FloatingButton,
        DIDListCard,
    },
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        showNewDIDs: false,
        //addDidOptions: {"admin":[1,2,3],"managedWholesale":[1],"partner":[1],"customer":[1]},

    }),
  /**
   * This prevents page redirect if the AddDIDDialog is open.
   * This redirect happens on error.
   * Because the AddDIDDialog is processing multiple DIDs, any one of the requests can fail.
   * If one those request fail is seems to cause a redirect.
   * Hence this method prevents that redirect. Note this will display an error in the console RE the redirect.
   * At this point im not 100% why the page redirect then a route fails.
   *
   * @param to
   * @param from
   * @param next
   */

    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    },
  computed:{
      addDidOptions: function( ){
        //what we return here is based on the type of customer.
        //if customer is managed then we can only use option 1 where the user selected from the Managed Wholesale Users pool.
        let ret = {"admin":[1],"managedWholesale":[1],"partner":[1],"customer":[1]};
        if(this.customerData && this.customerData.i_parent == null){
          ret = {"admin":[1,2,3],"managedWholesale":[1],"partner":[1],"customer":[1]};
        }
        return ret;
      }
  }
};
</script>
