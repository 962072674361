<template>
  <div>
    <router-view />
    <FloatingButton v-if="customerData.status != 'closed'" tooltip="New Payment" icon="mdi-currency-usd" @click="showNewPayment=true" :busy="customerLoading" />
    <ProcessPaymentDialog v-model="showNewPayment" :data="customerData" />
  </div>
</template>

<script>
    import FloatingButton from '@/components/pieces/FloatingButton';
    import ProcessPaymentDialog from '@/components/dialogs/Customer/ProcessPaymentDialog';
    import customerDataMixin from '@/mixins/customerDataMixin';
    export default {
      name: 'CustomerBilling',
      mixins: [customerDataMixin],
      components: { ProcessPaymentDialog, FloatingButton },
      data: () => ({
          invoices: {},
          showNewPayment: false,
        }),
      beforeRouteUpdate(to, from, next){
        return (this.showNewPayment) ? next(false) : next();
      },
      beforeRouteLeave(to, from, next){
        return (this.showNewPayment) ? next(false) : next();
      }

    };
</script>