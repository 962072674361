<template>
    <div>
        <v-sheet v-if="!!subProduct" rounded class="pa-4" color="background_accent">
            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
                    <p class="text-h6 mb-1">{{subProduct.channels}} Line {{mainProduct.name}}</p>
                    <p><span class="text-subtitle-1">{{subProduct.cost_gst_incl | currency}} per month </span><span class="text--disabled">including GST</span></p>
                    <p>
                        <strong v-if="mainProduct.name === 'PLUS'" class="font-weight-bold">This plan is not for general sale and is limited to one per partner. </strong>This plan allows {{subProduct.channels}} concurrent call<span v-if="subProduct.channels!==1">s</span>.
                        <span v-if="mainProduct.name === 'NFR'">Includes outbound calls to local/national numbers, 13/1300/1800 numbers and other numbers hosted with Breeze Connect. This plan also includes up to {{subProduct.mobile_minutes | thousandSeparator}} minutes of calls to Australian mobiles each month.</span>
                        <span v-else>Includes outbound calls to 1800 numbers and other numbers hosted with Breeze Connect.</span>
                        All other calls are billed at standard rates.

                    </p>
                    <p>For full details refer to the <a :href="mainProduct.url" target="_blank">Critical Information Summary</a>.</p>
                </v-col>
                <v-col cols="12" md="6">
                    <v-simple-table style="max-width: 600px;" dense v-if="!!mainProduct">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Calls to
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Local/national numbers
                                </td>
                                <td>
                                    <span v-if="mainProduct.name === 'PLUS'">Included</span>
                                    <span v-else>9c per call</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Australian mobiles</td>
                                <td>
                                    <span v-if="mainProduct.name === 'PLUS'">{{subProduct.mobile_minutes | thousandSeparator}} minutes included<br/><span style="font-size:11px;">then 14c per minute + 14c connection fee</span></span>
                                    <span v-else>14c per minute<br/><span style="font-size:11px;">plus 14c connection fee</span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>13/1300 numbers</td>
                                <td>
                                    <span v-if="mainProduct.name === 'PLUS'">Included</span>
                                    <span v-else>28c per call</span>
                                </td>
                            </tr>
                            <tr>
                                <td>1800 numbers</td>
                                <td>Included</td>
                            </tr>
                            <tr>
                                <td>Breeze Connect numbers</td>
                                <td>Included</td>
                            </tr>
                            <tr>
                                <td>Satellite numbers (014x)</td>
                                <td>$3.00 per min</td>
                            </tr>
                            <tr>
                                <td>International calls</td>
                                <td>
                                    <span v-if="mainProduct.name === 'NFR'">Not available</span>
                                    <span v-else>Standard international rates apply<br/><span style="font-size:11px;">minimum charge 60 seconds</span></span>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet v-if="!!addonProduct" rounded class="mt-4 pa-4" color="background_accent">
            <v-row no-gutters align="center" style="font-size: 13px;">
                <v-col cols="12" md="6" class="pr-md-4">
                    <p class="text-h6 mb-1">{{addonProduct.mobile_minutes | thousandSeparator}} Mobile Minute Pack</p>
                    <p><span class="text-subtitle-1">{{addonProduct.cost_gst_incl | currency}} per month </span><span class="text--disabled">including GST</span></p>
                    <p>This add-on includes up to {{addonProduct.mobile_minutes | thousandSeparator}} additional minutes of calls to Australian mobiles each month.</p>
                    <p>For full details refer to the <a href="https://static.breezeconnect.com.au/documents/cis/BreezeConnectMobilePacksforCapped-CIS.pdf" target="_blank">Critical Information Summary</a>.</p>
                </v-col>
                <v-col cols="12" md="6">
                    <v-simple-table style="max-width: 600px;" dense>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Calls to
                            </th>
                            <th class="text-left">
                                Inclusions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Australian mobiles</td>
                            <td>
                                {{addonProduct.mobile_minutes | thousandSeparator}} minutes included<br/>
                                <span style="font-size:11px;">in addition to plan minutes</span>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet
            color="background_accent"
            rounded
            class="pa-4 mt-4 text-right"
            v-if="!!subProduct"
        >
            <span class="text-h5 text--disabled">Monthly Plan Fees </span><span class="text-h3">{{parseFloat(subProduct.cost_gst_incl) + (!!addonProduct ? parseFloat(addonProduct.cost_gst_incl) : 0) | currency}}</span>
        </v-sheet>
        <v-sheet v-if="!subProduct && !!data && (!!data.assigned_addons && data.assigned_addons.length===0 || !data.assigned_addons)" rounded class="pa-4 mb-4" color="background_accent">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
              <p class="text-h6 mb-1">No plan selected</p>
              <p><span class="text-subtitle-1">$0.00 per month </span></p>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table style="max-width: 600px;" dense>
                <thead>
                <tr>
                  <th class="text-left">
                    Calls to
                  </th>
                  <th class="text-left">
                    Cost
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    All destinations
                  </td>
                  <td>Not available</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
                <strong class="font-weight-bold red--text">This SIP trunk will be unable to make/receive calls until a plan is selected.</strong>
            </v-col>
          </v-row>
        </v-sheet>
    </div>
</template>

<script>
import planDataMixin from '../../../mixins/planDataMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'TrunkPlanInfoSummary',
    mixins: [planDataMixin, GlobalHelperMixin],
    data: () => ({
        mainProduct: null,
        subProduct: null,
        addonProduct: null,
        type: null
    }),
    props: {
      data: {
        type: Object,
        default: null,
      },
      products: {
        type: Array,
        required:true,
      },
      trunkData: {
        type: Object,
        default: null,
      },
    },
    methods: {
        findPlan(item) {
            let plan = this.planData.plans.find(f => f.value === item.i_product);
            if (plan) {
                if (!plan.addOn) {
                    this.mainProduct = plan;
                    this.type = this.planData.types.find(f => f.value === plan.type);
                } else {
                    this.addonProduct = plan;
                }
            }
        },
    },

    created() {
        //if (this.data && this.data.assigned_addons) this.data.assigned_addons.forEach(item => this.findPlan(item));
      if (this.data && this.data.mainProduct){
        this.mainProduct = this.products.find(f => f.i_product == this.data.mainProduct.i_product);
        if (this.data.subProduct){
          this.subProduct = this.mainProduct.options.find(f => f.i_product == this.data.subProduct.i_product);
          if(this.data.productOption){
            this.addonProduct = this.mainProduct.options2.find(f => f.i_product == this.data.productOption.i_product);
          }
        }
      }else if(this.trunkData && this.trunkData.i_account){
        this.mainProduct = this.products.find(f => f.i_product == this.trunkData.i_product);
        for (let i = 0; i < this.trunkData.assigned_addons.length; i++) {
          this.subProduct = this.mainProduct.options.find(e => e.i_product == this.trunkData.assigned_addons[i].i_product);
          if (this.subProduct) {
            break;
          }
        }
        if (!this.subProduct) {
          //no plans select. We use the 'empty' plans.
          this.subProduct = this.mainProduct.options.find(e => e.i_product == null);
          if(this.subProduct && this.subProduct.options) {
            this.addonProduct = this.subProduct.options.find(e => e.i_product == null);
          }
        } else {
          //now search options. Note we can search the same addon list here. As the subProduct i_product will NOT exists in the subProduct.options
          if(this.subProduct.options) {
            for (let i = 0; i < this.trunkData.assigned_addons.length; i++) {
              this.addonProduct = this.subProduct.options.find(e => e.i_product == this.trunkData.assigned_addons[i].i_product);
              if (this.addonProduct) {
                break;
              }
            }

            if (!this.addonProduct) {
              //use empty plans
              this.addonProduct = this.subProduct.options.find(e => e.i_product == null);
            }
          }
        }
      }else{
        throw 'Failed to load product for summary.';
      }
    }
};
</script>
<style scoped>
    table > tbody > tr:hover {
        background: transparent !important;
    }
    table > tbody > tr > td {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
    table > thead > tr > th {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
</style>