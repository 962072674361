export default {
    computed: {
        trunkLoading() {
            if (this.$store.state.loading['trunk']) {
                return true;
            } else {
                return false;
            }
            //return this.$store.state.loading.trunk;
        },
        trunkData() {
            if (
                this.$store.state.data.trunk &&
                this.$store.state.data.trunk.i_account ===
                parseInt(this.$route.params.trunkId)
            ) {
                return this.$store.state.data.trunk;
            }
            return {};
        },
    },
    methods:{
        trunkLabel(trunkData){
            if(trunkData.account_type == 'SIP-TRUNK'){
                return 'SIP-Trunk';
            }else if(trunkData.account_type == 'HOST-EXTENSION'){
                return 'Extension'
            }else if(trunkData.account_type == 'RING-GROUP'){
                return 'Ring Group'
            }else if(trunkData.account_type == 'CALL-QUEUE'){
                return 'Call Queue'
            }else if(trunkData.account_type == 'AUTO-ATTENDTION'){
                return 'Auto Attendant'
            }else{
                return 'Unknown';
            }
        }
    },
};