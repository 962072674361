<template>
    <BasicCard :loading="loading || !data || !data.products_display" title="Plan Details" :no-skeleton="!!data && !!data.products_display">
        <v-row no-gutters v-if="!!data.products_display && data.products_display.length">
            <v-col
                cols="12"
                v-for="item in data.products_display"
                v-bind:key="item.i_product"
            >
                <v-sheet class="pa-4 mb-2" rounded color="background_accent">
                    <v-chip label small class="float-right">Active</v-chip>
                    <h3 class="text--primary">{{ item.end_user_name }}</h3>
                    <p class="mb-0" v-if="item.addon_effective_from != null || item.subscription_effective_fee != null">
                        <Currency v-if="item.subscription_effective_fee != null" :value="item.subscription_effective_fee ? item.subscription_effective_fee : 0" add-gst hide-tooltip /> per month
                        <span v-if="item.addon_effective_from != null"><br />Effective from <LocalDateTime :value="item.addon_effective_from" /></span>
                    </p>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="12">
                <v-alert
                    text
                    type="warning"
                >This trunk has no assigned plan!</v-alert>
            </v-col>
        </v-row>
        <template v-slot:actions>
            <v-btn
                v-if="$route.name != 'TrunkBilling'"
                :to="{ name: 'TrunkBilling' }"
                text
            ><v-icon v-html="'mdi-cash-usd'" left /> Billing</v-btn>
            <v-btn
                @click="viewPlanDialog=true"
                text
            ><v-icon v-html="'mdi-information-outline'" left /> Details</v-btn>
            <v-btn
                v-if="!data.isWholesaleByo"
                @click="changePlanDialog=true"
                text
            ><v-icon v-html="'mdi-pencil'" left /> Edit</v-btn>
        </template>
        <ViewTrunkPlanDialog
            v-if="(data.account_type == 'SIP-TRUNK' && (!data.isWholesaleByo && !data.isWholesaleManaged)) && data.i_account && products.length > 0"
            v-model="viewPlanDialog"
            :trunkData="data"
            :products="products"
        />
      <ViewTrunkProductsDialog v-if="(data.account_type != 'SIP-TRUNK' || data.isWholesaleByo || data.isWholesaleManaged) && data.i_account && products.length > 0"
                               v-model="viewPlanDialog"
                               :data="data"
                               :products="products"
                               />
        <ChangeTrunkPlanDialog
            v-model="changePlanDialog"
            :data="data"
            show-nfr
        />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import Currency from '../../pieces/Currency';
import ChangeTrunkPlanDialog from '../../dialogs/Trunk/ChangeTrunkPlanDialog';
import ViewTrunkPlanDialog from '../../dialogs/Trunk/ViewTrunkPlanDialog';
import ViewTrunkProductsDialog from "../../dialogs/Trunk/ViewTrunkProductsDialog";

export default {
    name: 'TrunkProductsCard',
    components: {
      ViewTrunkProductsDialog,
        ViewTrunkPlanDialog,
        ChangeTrunkPlanDialog,
        Currency,
        LocalDateTime,
        BasicCard,
    },
    data: () => ({
      changePlanDialog: false,
      viewPlanDialog: false,
      products:[],
    }),
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
      /**
       * Note that the changeTrunkPlanDialog also calls the products.
       * The cahngeTrunkPlanDialog is used in other places in the site hence it makes sense to it to call its own
       * list of product so its using an up-to-date list. ESP considering the introduction of dynamic products.
       * @returns {Promise<void>}
       */
      async getProducts( ){
        this.busy = true;
        let response = null;
        if(this.data.account_type.toLowerCase() == 'sip-trunk'){
          response = await this.Api.send('get', 'sip-trunks/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'hosted-extension'){
          response = await this.Api.send('get', 'extensions/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'ring-group'){
          response = await this.Api.send('get', 'ring-groups/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'fax-to-mail'){
          response = await this.Api.send('get', 'fax-to-mail/products/'+this.data.i_customer);
        }else if(this.data.account_type.toLowerCase() == 'auto-attendant'){
          response = await this.Api.send('get', 'auto-attendant-accounts/products/'+this.data.i_customer);
        }else{
          alert('Products for account type ('+this.data.account_type+') not supported.');
        }
        this.busy = false;
        if(response.success){
          this.products = response.data;
        }
      },
    },
  created() {
      if(this.data.i_customer){
        this.getProducts();
      }
  },
  watch: {
    data(value) {
      if (value.i_customer) {
        this.getProducts();
      }
    }
  },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        data: {
          type: Object,
          default: null,
        },
    },
};
</script>
