<template>
    <SimpleDialog
        :loading="busy"
        v-model="visible"
        @close="close"
        yes-text="Confirm"
        @click="terminate"
        :yesDisabled="!yesEnabled"
        no-text="Cancel"
        :title="'Delete '+accountData.account_type_label+' \'' + accountId + '\'?'"
        persistent

        styled-header
        errorHeader
        yesColor="error"
    >

      <v-alert type="warning" v-if="accountData.account_type == 'HOSTED-EXTENSION' && accountData.extensionDetails.total_connected_ring_groups > 0">
        <strong>Note: </strong> This Extension is assigned to {{accountData.extensionDetails.total_connected_ring_groups}} Ring Group(s). Deleting this Extension will remove the Extension from all associated Ring Groups.
      </v-alert>

      <v-alert type="warning" v-if="accountData.account_type == 'HOSTED-EXTENSION' && accountData.extensionDetails.total_attached_voip_devices > 0">
      <strong>Note: </strong> This Extension has {{accountData.extensionDetails.total_attached_voip_devices}} attached Device(s). Deleting this Extension will deregister all attached devices and delete them.
        Consider moving all these devices to a new Extension if you do not wish to lose this information.
    </v-alert>

      <v-alert type="warning" v-if="accountData.account_type == 'HOSTED-EXTENSION' && (extensionTargetedTransitions['not_active'].length > 0 || extensionTargetedTransitions['actions'].length > 0)">
        <span v-if="extensionTargetedTransitions['not_active'].length >0">
          This Extension is assigned Inactive Actions
          <ul>
            <li v-for="item in extensionTargetedTransitions['not_active']"
                :key="item.i_menu_transition"><a @click="openAutoAttendant(item.auto_attendant_i_account, item.i_menu)">{{
                item.auto_attendant_name
              }}</a></li>
          </ul>
        </span>
        <span v-if="extensionTargetedTransitions['actions'].length>0">
          This Extension is assigned Auto Attendant Actions
          <ul>
            <li v-for="item in extensionTargetedTransitions['actions']"
                :key="item.i_menu_transition"><a @click="openAutoAttendant(item.auto_attendant_i_account, item.i_menu)">{{
                item.auto_attendant_name
              }}</a></li>
          </ul>
        </span>
      </v-alert>

      <v-alert type="warning" v-if="accountData.account_type == 'RING-GROUP' && accountData.ringGroupDetail && accountData.ringGroupDetails.i_c_queue">
        <strong>Note: </strong> This Ring Group is linked to a Call Queue. Deleting this Ring Group will also delete the Ring Queue.
      </v-alert>
      <span  v-if="validationMessage != null">
        <p class="text--primary">Unable to delete {{accountData.account_type_label}}.</p>
        <v-alert type="error" v-if="validationMessage != null">{{validationMessage}}</v-alert>
      </span>
      <span  v-if="validationMessage == null">
        <v-alert type="error">This operation cannot be undone.</v-alert>


        <p class="text--primary">This {{accountData.account_type_label}} will be deleted immediately.</p>

        <p class="text--primary">
            To delete this {{accountData.account_type_label}}, type 'delete' in the box below:
        </p>

        <v-text-field
            v-model="confirmDeletion"
            autofocus
            outlined
            dense
            :rules="rules"
            :disabled="busy"
        />
      </span>


        <v-alert v-if="error" type="warning">Error: {{ error }}</v-alert>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'TerminateTrunkDialog',
    mixins: [apiMixin,dialogMixin],
    components: {SimpleDialog},
    data: () => ({
        busy: false,
        confirmDeletion: '',
        rules: [(v) => /^delete$/i.test(v) || "Enter 'delete' to confirm"],
        error: null,
        validationMessage:null,
        extensionTargetedTransitions: {
          'not_active':[],
          'actions':[]
        },
    }),
    props: {
        accountId: {
            type: Number,
            required:true,
        },
      //added at a later date than accountID
      accountData: {
        type: Object,
        required:true,
      },
    },
    methods: {
        async terminate() {
            this.busy = true;
            let response = null;
            if(this.accountData.account_type == 'HOSTED-EXTENSION'){
              response = await this.Api.send('delete', 'extensions/' + this.accountData.extensionDetails.i_c_ext);
            }else {
              response = await this.Api.send('delete', 'trunks/' + this.accountId);
            }
            if (response.success) {
              if(response.data.i_account == this.accountId){
                this.showGlobalSuccessMessage('Deleted successfully.');
                this.visible = false;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                //location.reload();
              }else{
                  this.error = response.data.message;
              }
            } else {
                this.error = response.data.description;
            }
          this.busy = false;
        },
        close() {
            this.$emit('close');
        },
        reset( ){
          this.confirmDeletion = '';
          this.error = null;
          this.validationMessage = null;
          this.extensionTargetedTransitions = {
            'not_active':[],
            'actions':[]
          },
          this.update( );
        },
      update( ){
        if(this.accountData.account_type == 'HOSTED-EXTENSION'){
          this.getExtensionTargetedTransitions( );
        }
      },
      async getExtensionTargetedTransitions( ){
        this.busy = true;
        let response = await this.Api.send('get','extensions/'+this.accountData.extensionDetails.i_c_ext+'/targeted-auto-attendant-transitions');
        if(response.success){
          this.extensionTargetedTransitions = response.data;
        }
        this.busy = false;
      },
      async validate() {
          this.busy = true;
          const response = await this.Api.send('get',
              'trunks/' + this.accountId + '/delete-validation'
          );
          if (response.data.success) {
            this.busy = false;
            this.validationMessage = null;
          } else {
            this.busy = false;
            this.validationMessage = response.data.message;
          }
      },
    },
  watch: {
    visible(value) {
      if (this.value!==value) this.$emit('input', value);

      if(value){
        this.reset( ); //note we only reset on open as after a delete data is not longer the samem so refresh on close cases problems. IE when deleting an extension
        this.validate( );
      }

    },
  },
  computed:{
    yesEnabled:function(){
      return this.confirmDeletion.toLowerCase() === 'delete' && !this.busy && this.validationMessage == null;
    },
  }
};
</script>
<style scoped>
.v-alert a{
  color:#ffffff;
  text-decoration:underline;
}
</style>
