<template>
    <v-row dense>
        <v-col cols="12" md="6">
            <CustomerOutstandingBalanceCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
        <v-col cols="12" md="6">
            <CustomerOutstandingInvoiceCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
        <v-col cols="12" md="6">
            <CustomerPaymentInfoCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
        <v-col cols="12" md="6">
            <CustomerBillingDetailCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
    </v-row>
</template>

<script>

    import CustomerOutstandingInvoiceCard from '../../components/cards/Customer/CustomerOutstandingInvoiceCard';
    import CustomerBillingDetailCard from '../../components/cards/Customer/CustomerBillingDetailCard';
    import CustomerOutstandingBalanceCard from '../../components/cards/Customer/CustomerOutstandingBalanceCard';
    import CustomerPaymentInfoCard from '../../components/cards/Customer/CustomerPaymentInfoCard';
    import resellerDataMixin from "../../mixins/resellerDataMixin";
    import refreshListenerMixin from "../../mixins/refreshListenerMixin";
    export default {
      name: 'WholesaleManagedBillingSummary',
      components: { CustomerPaymentInfoCard, CustomerOutstandingBalanceCard, CustomerBillingDetailCard, CustomerOutstandingInvoiceCard },
      mixins: [resellerDataMixin, refreshListenerMixin],
      data: () => ({
      }),
      methods: {
        update() {
          this.get(this.resellerId, true,'reseller');
        },
      },
    };
</script>