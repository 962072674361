<template>
    <div>
      <v-row no-gutters>
        <v-col
            v-for="(item, key) in products"
            :key="key" :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined"
        >
          <v-hover v-slot="{ hover }">
            <v-card
                v-ripple
                outlined
                :elevation="firstProduct && firstProduct.i_product ==item.i_product ? 4 : hover ? 2 : 0"
                :color="firstProduct && firstProduct.i_product ==item.i_product ? highlightColor : hover ? 'background_accent' : undefined"
                :height="($vuetify.breakpoint.smAndDown ? 106 : 166) + (firstProduct && firstProduct.i_product ==item.i_product ? 10 : 0)"
                class="px-2 text-center"
                :class="firstProduct && firstProduct.i_product ==item.i_product ? 'ma-2 py-5' : 'ma-3 py-4'"
                @click="changeFirstProduct(item)"
            >
              <v-row no-gutters align="center" justify="center" class="fill-height">
                <v-col>
                  <v-icon v-html="item.icon" color="secondary" size="48" :class="$vuetify.breakpoint.smAndDown ? 'float-left ma-2' : 'mb-2'" />
                  <h3 class="mb-0">{{item.name}}</h3>
                  <p class="subtitle-2" v-if="item.description != null || item.price_label != null">
                    <span class="text--disabled">{{item.description}}</span><br />
                    <strong v-if="item.price_label!=null">{{item.price_label}}</strong>
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <template v-if="showOptions">
        <v-expand-transition  v-for="(item, key) in products" :key="key">
          <PlanSelectSlider v-if="firstProduct && firstProduct.i_product  == item.i_product && ($vuetify.breakpoint.width < 1070 || item.options.length > maxAddonProductDisplayCount)"
                            v-model="secondProduct"
                            :items="item.options"
                            :itemWidth="item.options.length == 2 ? 423 : item.options.length > 2 ? 316 : 150"
                            :itemHeight="150"
                            :dynamicProduct="true"
                            />
          <v-row no-gutters v-if="firstProduct && firstProduct.i_product  == item.i_product && item.options.length < (maxAddonProductDisplayCount +1) && $vuetify.breakpoint.width > 1069">
            <v-col v-for="(subItem, subKey) in item.options"  :key="subKey" :cols="12/item.options.length">
              <v-hover v-slot="{ hover }">
                <v-card
                    v-ripple
                    outlined
                    :elevation="(firstProduct && firstProduct.i_product  == item.i_product && secondProduct && secondProduct.i_product == subItem.i_product) ? 4 : hover ? 2 : 0"
                    :color="(firstProduct && firstProduct.i_product  == item.i_product && secondProduct && secondProduct.i_product == subItem.i_product) ? highlightColor : hover ? 'background_accent' : undefined"
                    :height="($vuetify.breakpoint.xsOnly ? 90 : 150) + ((firstProduct && firstProduct.i_product  == item.i_product && secondProduct && secondProduct.i_product == subItem.i_product) ? 10 : 0)"
                    class="px-2 text-center"
                    :class="(firstProduct && firstProduct.i_product  == item.i_product && secondProduct && secondProduct.i_product == subItem.i_product) ? 'ma-2 py-5' : 'ma-3 py-4'"
                    @click="changeSecondProduct(subItem)"
                >
                  <h3 :class="((subItem.description == null || subItem.description == '') ? 'mt-3 ' : '') + 'mb-0'">{{subItem.title}}</h3>
                  <p class="text--disabled subtitle-2">{{subItem.description}}</p>
                  <p v-if="subItem.price_label!=null"><strong style="font-size:xx-large">{{subItem.price_label}}</strong>
                    <span v-if="subItem.price_sub_label != null"><br/>{{subItem.price_sub_label}}</span>
                  </p>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-expand-transition>
      </template>
    </div>
</template>

<script>
import PlanSelectSlider from "../Forms/PlanSelectSlider";
export default {
  /**
   * The components cater for the disp
   */
    name: 'TrunkProductForm',
    mixins: [],
    components: { PlanSelectSlider},
    data: () => ({
        busy: false,
        firstProduct: null,
        secondProduct: null,

        originalFirstProduct: null,
        originalSecondProduct: null,
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
        dirtyAndValid() {
          if(!this.firstProduct){
            return false;
          }

          if(this.firstProduct.options && this.firstProduct.options.length > 0){
            //first check if valid.
            if(this.firstProduct == null || this.secondProduct == null){
              //not valid
              return false;
            }

            if(this.originalFirstProduct == null && this.firstProduct != null && this.firstProduct.i_product != null){
              return true;//dirty
            }

            if(this.firstProduct.i_product != this.originalFirstProduct.i_product){
              return true; //dirty
            }

            if(this.originalSecondProduct == null && this.secondProduct != null && this.secondProduct.i_product != null){
              return true;//dirty
            }

            if(this.originalSecondProduct != null && this.secondProduct.i_product != this.originalSecondProduct.i_product){
              return true; //dirty
            }

            return false;

            //return (this.firstProduct.i_product != this.originalFirstProduct.i_product || this.secondProduct && this.secondProduct.i_product != this.originalSecondProduct.i_product) && (this.firstProduct != null && this.secondProduct != null);
          }else{
            return (this.firstProduct.i_product != this.originalFirstProduct.i_product);
          }
        }
    },
    watch: {
      firstProduct(value) {
        this.update();
      },
      secondProduct(value) {
        this.update();
      }
    },
    methods: {
        changeSecondProduct(value) {
          this.secondProduct = value;
        },
        changeFirstProduct(value) {
          this.firstProduct = value;
        },
        update() {
            if (this.dirtyAndValid) {
              if(this.trunkData.account_type == 'SIP-TRUNK' || this.type=='sip-trunk'){
                this.$emit('input', {
                  mainProduct: this.firstProduct,
                  subProduct: this.secondProduct,
                  productOption: null,
                });
              }else {
                this.$emit('input', {
                  subProduct: this.firstProduct,
                  productOption: this.secondProduct
                });
              }
            } else {
              this.$emit('input', null);
            }
        },
    },
    filters: {
        thousandSeparator: function(value) {
            if (!value) value = 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    created() {
      if(this.trunkData.i_account){
        /**
         * Note that depending on the trunk type depends on how we handle the product.
         * Some trunks have a main product that is always applied and is not optional.
         * SipTrunk product have a main product that can be selected by the user.
         * Hosted PBX trunks had static main products.
         */

        //organise tier 1
        let self = this;
        if(this.trunkData.account_type == 'SIP-TRUNK'){
          this.firstProduct = this.products.find(e => e.i_product === this.trunkData.i_product);
        }else{
          this.trunkData.assigned_addons.forEach(function (addon, index) {
            if(!self.firstProduct){
              self.firstProduct = self.products.find(e => e.i_product === addon.i_product);
            }
          });
        }

        if(!this.firstProduct){
          //no plans select. We use the 'empty' plans.
          this.firstProduct = this.products.find(e => e.i_product == null);
          if(this.firstProduct.options) {
            this.secondProduct = this.firstProduct.options.find(e => e.i_product == null);
          }
        }else {
          //now search options.
          // Note if not using the main product we can search the same addon list here. As the firstProduct i_product will NOT exists in the firstProduct.options
          // If we are using the main product then the addon list should only have one product that we use now.
          if(this.firstProduct.options) {
            this.trunkData.assigned_addons.forEach(function (addon, index) {
              if (!self.secondProduct) {
                self.secondProduct = self.firstProduct.options.find(e => e.i_product === addon.i_product);
              }
            });
            if(!this.secondProduct){
              //use empty plans
              this.secondProduct = this.firstProduct.options.find(e => e.i_product == null);
            }
          }
        }
        this.originalFirstProduct = this.firstProduct;
        this.originalSecondProduct = this.secondProduct;
      }else {
        if (!this.firstProduct) {
          //we set the original product to nothing for a newly created trunk. This triggers the isDirty toggle when a plan is selected.
          //EG really null is no selection but some options (EG no call recording) set the value to null. Hence we set to 'blank' in order to trigger isDirty if a value is set to null

          this.firstProduct = {i_product: 'blank'};
          this.originalFirstProduct = this.firstProduct;
          this.secondProduct = this.firstProduct;
          this.originalSecondProduct = this.firstProduct;
        }
      }

      /*
      We need to build the watcher here as the watcher fires after the created method and clears the secondProduct value.
      Using the beforeCreate method does not work as it cannot access planDetails. Not sure why but I could not get it working and found this solution.
       */
      this.$watch('firstProduct', () => {
        this.update(); //clear the submitted values. Needs a product option
        this.secondProduct = null;
      });

    },
    props: {
      /**
       * Pass in trunkData so we can see the current product.
       *
       */
      trunkData: {
          type: Object,
          default: function() { return {}; },
        },
      /**
       * Customer who is creating the trunk (who will own the trunk).
       */
      i_customer: {
        type: Number,
        required:true,
      },
      /**
       * Product available to select.
       */
      products: {
        type: Array,
        required:true,
      },
      /**
       * This tell the component if we want to show the select row of options for the product.
       * Note at the time of writing this component only support 2 levels of products.
       */
      showOptions:{
        type: Boolean,
        default: true,
      },
      /**
       * How many addon products are shown before the UI is change to a slide.
       * EG if set to 2 the UI will show 2 addon product on the screen.
       * Hence each taking up 50% with. If a product has more than 2 addon product
       * then the UI will change from showing 2 addon products to showing a slider to select an addon product.
       */
      maxAddonProductDisplayCount:{
        type: Number,
        default: 2
      },

      type:{
        type: String,
        default: 'sip-trunk',
        validator: function (value) {
          // The value must match one of these strings
          return ['sip-trunk','hosted-extension','fax-to-mail','ring-group','auto-attendant'].indexOf(value) !== -1
        }
      },
    }
};
</script>