<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6" lg="4">
        <AutoAttendantGeneralSettingsCard :trunkData="trunkData" :aaData="aaData" :loading="loading"/>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <AutoAttendantPromptsCard :trunkData="trunkData" :aaData="aaData" :loading="loading"/>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <AutoAttendantInputErrorsCard :trunkData="trunkData" :aaData="aaData" :loading="loading"/>
      </v-col>
   </v-row>
    <v-row dense>
      <v-col cols="12">
        <AutoAttendantActionListCard  :trunkData="trunkData" :aaData="aaData" :loading="loading"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AutoAttendantGeneralSettingsCard from "../../components/cards/AutoAttendant/AutoAttendantGeneralSettingsCard";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import AutoAttendantPromptForm from "../../components/pieces/AutoAttendant/AutoAttendantPromptForm";
import AutoAttendantPromptsCard from "../../components/cards/AutoAttendant/AutoAttendantPromptsCard";
import AutoAttendantInputErrorsCard from "../../components/cards/AutoAttendant/AutoAttendantInputErrorsCard";
import AutoAttendantActionListCard from "../../components/cards/AutoAttendant/AutoAttendantActionListCard";
import trunkDataMixin from "../../mixins/trunkDataMixin";
export default {
    name: 'AutoAttendantAccountAutoAttendantSummary',
    mixins: [refreshListenerMixin, trunkDataMixin],
    data: () => ({
        busy:false,
        dialogs: {
            newDID: false,
        },
        changeAddressDialog:false,
        extensionRingGroupsResponse:null
    }),
    components: {
      AutoAttendantActionListCard,
      AutoAttendantInputErrorsCard,
      AutoAttendantPromptsCard,
      AutoAttendantGeneralSettingsCard,
    },

    computed: {
      data( ){
        return this.trunkData;
      },
      aaData() {
        return this.fetchStoreGetData('autoAttendant', {"i_menu":this.$route.params.menuId,"i_account":this.iAccount})
      },
      loading() {
        return this.trunkLoading || this.busy || this.fetchStoreLoadingByLabel('autoAttendant');
      },
      iMenu( ){
        return this.$route.params.menuId
      },
      iAccount( ){
        if(!this.trunkData){
          return null;
        }else{
          return this.trunkData.i_account;
        }
      },
      trunkData() {
        if (
            this.$store.state.data.trunk &&
            this.$store.state.data.trunk.i_account ===
            parseInt(this.$route.params.trunkId)
        ) {
          return this.$store.state.data.trunk;
        }
        return {};
      },
    },
  created: function( ) {
    if (this.iAccount) {
      this.update();
    }
  },
  watch: {
    iAccount(val) {
      if(val){
        this.update();
      }
    },
    iMenu(val) {
      if(val){
        this.update();
      }
    }
  },
  methods: {
    async get(id, force) {
      let redirect = {
        name: 'TrunkSummary',
        params:{trunkId : this.trunkData.i_account,customerId: this.trunkData.i_customer},
      };
      this.fetchStoreRequest('autoAttendant', 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+id, {"i_menu":id,"i_account":this.trunkData.i_account}, redirect, 'get', force, 'Auto Attendant');
      this.buildBreadCrumbs();
    },
    update() {
      this.get(this.$route.params.menuId, true);
    },

    buildBreadCrumbs( ){
      let breadCrumbs = [
        {
          name:"AutoAttendantAccountAutoAttendantSummary",
          label: "Auto Attendant ("+this.$route.params.menuId+")",
          params: {
            customerId: this.trunkData.i_customer,
            accountId: this.trunkData.i_account,
            menuId: this.$route.params.menuId,
          }
        }
      ];
      this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
    }
  },

};
</script>
