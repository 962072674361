import { mdiHandshake } from '@mdi/js';

export default {
    data: () => {
        return ({
            planData: {
                plans: [
                    {
                        value: null,
                        alternate_text: 'No plan',
                        type: (process.env.VUE_APP_PAYG_I_PRODUCT !== undefined ? parseInt(process.env.VUE_APP_PAYG_I_PRODUCT) : 88),
                    },
                    {
                        value: 13,
                        channels: 1,
                        cost: 8,
                        type: 88
                    },
                    {
                        value: 14,
                        channels: 2,
                        cost: 14,
                        type: 88
                    },
                    {
                        value: 15,
                        channels: 3,
                        cost: 19,
                        type: 88
                    },                    {
                        value: 16,
                        channels: 4,
                        cost: 24,
                        type: 88
                    },
                    {
                        value: 20,
                        channels: 8,
                        cost: 28,
                        type: 88
                    },
                    {
                        value: 24,
                        channels: 12,
                        cost: 38,
                        type: 88
                    },
                    {
                        value: 28,
                        channels: 16,
                        cost: 48,
                        type: 88
                    },
                    {
                        value: 36,
                        channels: 24,
                        cost: 69,
                        type: 88
                    },
                    {
                        value: 44,
                        channels: 32,
                        cost: 90,
                        type: 88
                    },
                    {
                        value: 76,
                        channels: 64,
                        cost: 160,
                        type: 88
                    },
                    {
                        value: null,
                        alternate_text: 'No plan',
                        type: 120
                    },
                    {
                        value: 111,
                        channels: 1,
                        cost: 59,
                        mobile: 1000,
                        type: 120
                    },
                    {
                        value: 112,
                        channels: 2,
                        cost: 118,
                        mobile: 2000,
                        type: 120
                    },
                    {
                        value: 113,
                        channels: 3,
                        cost: 177,
                        mobile: 3000,
                        type: 120
                    },
                    {
                        value: 114,
                        channels: 4,
                        cost: 232,
                        mobile: 4000,
                        type: 120
                    },
                    {
                        value: 115,
                        channels: 8,
                        cost: 456,
                        mobile: 8000,
                        type: 120
                    },
                    {
                        value: 116,
                        channels: 16,
                        cost: 896,
                        mobile: 16000,
                        type: 120
                    },
                    {
                        value: 117,
                        channels: 32,
                        cost: 1760,
                        mobile: 32000,
                        type: 120
                    },
                    {
                        value: null,
                        alternate_text: 'No mobile addon',
                        type: 120,
                        addOn: true
                    },
                    {
                        value: 129,
                        cost: 250,
                        mobile: 5000,
                        type: 120,
                        addOn: true
                    },
                    {
                        value: 130,
                        cost: 500,
                        mobile: 10000,
                        type: 120,
                        addOn: true
                    },
                    {
                        value: 131,
                        cost: 750,
                        mobile: 15000,
                        type: 120,
                        addOn: true
                    },
                    {
                        value: 132,
                        cost: 1250,
                        mobile: 25000,
                        type: 120,
                        addOn: true
                    },
                    {
                        value: 133,
                        cost: 2000,
                        mobile: 50000,
                        type: 120,
                        addOn: true
                    },
                    {
                        value: 134,
                        cost: 3500,
                        mobile: 100000,
                        type: 120,
                        addOn: true
                    },
                    {
                        value: null,
                        alternate_text: 'No plan',
                        type: 11
                    },
                    {
                        value: 12,
                        channels: 2,
                        cost: 0,
                        type: 11
                    },
                    {
                        value: 99,
                        channels: 4,
                        cost: 14,
                        type: 11
                    }
                ],
                types: [
                    {
                        name: 'PAYG',
                        icon: 'mdi-finance',
                        description: 'Pay as you go - plans with no included calls',
                        value: (process.env.VUE_APP_PAYG_I_PRODUCT !== undefined ? parseInt(process.env.VUE_APP_PAYG_I_PRODUCT) : 88),
                        url: 'https://static.breezeconnect.com.au/documents/cis/BreezeConnectSIPTrunkingPAYG-CIS.pdf'
                    },
                    {
                        name: 'PLUS',
                        icon: 'mdi-chart-pie',
                        description: 'Plus - plans with included calls',
                        value: 120,
                        url: 'https://static.breezeconnect.com.au/documents/cis/BreezeConnectSIPTrunkingPLUS-CIS.pdf'
                    },
                    {
                        name: 'NFR',
                        icon: mdiHandshake,
                        description: 'Not for resale plans - for partner use only',
                        value: 11,
                        url: 'https://static.breezeconnect.com.au/documents/cis/BreezeConnectSIPTrunkingNFR-CIS.pdf',
                        nfr: true
                    }
                ],

            },
            // extensionPlanData:{
            //     types: [
            //         {
            //             name: 'No Plan',
            //             icon: 'mdi-close-circle-outline',
            //             description: 'Select to have no plan.',
            //             value: 'no-plan',
            //             cost: 0,
            //             price_label: 'No charge',
            //             options:[
            //                 {
            //                     title: 'No Plan',
            //                     price_label: 'No price',
            //                     description: 'No plan will be selected. Product will not function and no charges will be made.',
            //                     value: 'default',
            //                     cost: 0,
            //                 }
            //             ]
            //         },
            //         {
            //             name: 'PAYG',
            //             icon: 'mdi-finance',
            //             description: 'Pay as you go - with no included calls',
            //             value: 'payg',
            //             url: 'https://breezeconnect.com.au/wp-content/uploads/2021/08/HPBX-payg3.pdf',
            //             cost: 5.50,
            //             price_label: '$5.50 per month',
            //             options:[
            //                 {
            //                     title:'No Call Recording',
            //                     price_label: '$0',
            //                     cost: 0,
            //                     description: 'Calls will not be recorded.',
            //                     value: 'no-call-recording',
            //                 },
            //                 {
            //                     title:'With Call Recording',
            //                     price_label: '+ $2.95/month',
            //                     cost: 2.95,
            //                     description: 'Calls will be recorded with downloadable audio files.',
            //                     value: 'with-call-recording',
            //                 }
            //
            //             ]
            //         },
            //         {
            //             name: 'PLUS',
            //             icon: 'mdi-chart-pie',
            //             description: 'Plus - with included calls',
            //             value: 'plus',
            //             url: 'https://breezeconnect.com.au/wp-content/uploads/2021/08/HPBX-PLUS.pdf',
            //             cost: 33,
            //             price_label: '$33 per something',
            //             local_national_numbers_label: 'Included',
            //             numbers_13_1300_label: 'Included',
            //             mobile_minutes:500,
            //             options:[
            //                 {
            //                     title:'No Call Recording',
            //                     price_label: '$0',
            //                     cost: 0,
            //                     description: 'Calls will not be recorded.',
            //                     value: 'no-call-recording',
            //                 },
            //                 {
            //                     title:'With Call Recording',
            //                     price_label: '+ $2.95/month',
            //                     cost: 2.95,
            //                     description: 'Calls will be recorded with downloadable audio files.',
            //                     value: 'with-call-recording',
            //                 }
            //
            //             ]
            //         },
            //     ],
            // },
            // ringGroupPlanData:{
            //     types: [
            //         {
            //             name: 'No Plan',
            //             icon: 'mdi-close-circle-outline',
            //             description: 'Select to have no plan.',
            //             value: 'no-plan',
            //             cost: 0,
            //             price_label: 'No charge',
            //             /*options:[
            //                 {
            //                     title: 'No Plan',
            //                     price_label: 'No price',
            //                     description: 'No plan will be selected. Product will not function and no charges will be made.',
            //                     value: 'default'
            //                 }
            //             ]*/
            //         },
            //         {
            //             name: 'Ring Group',
            //             icon: 'mdi-phone-voip',
            //             description: 'Standard Ring Group plan',
            //             value: 'ring-group-channel-5',
            //             url: 'https://breezeconnect.com.au/our-policies/',
            //             cost: 0,
            //             price_label: 'No charge',
            //             /* we plan to have options some day to increase channels
            //             options:[
            //                 {
            //                     title:'No Call Recording',
            //                     price_label: '$0',
            //                     cost: 0,
            //                     description: 'Calls will not be recorded.',
            //                     value: 'no-call-recording',
            //                 },
            //                 {
            //                     title:'With Call Recording',
            //                     price_label: '+ $2.95/month',
            //                     cost: 2.95,
            //                     description: 'Calls will be recorded with downloadable audio files.',
            //                     value: 'with-call-recording',
            //                 }
            //
            //             ]
            //
            //              */
            //         },
            //     ],
            // },
            // faxtomailPlanData:{
            //     types: [
            //         {
            //             name: 'No Plan',
            //             icon: 'mdi-close-circle-outline',
            //             description: 'Select to have no plan.',
            //             value: 'no-plan',
            //             cost: 0,
            //             price_label: 'No charge',
            //         },
            //         {
            //             name: 'Fax To Mail',
            //             icon: 'mdi-fax',
            //             description: 'Fax To Mail Plan',
            //             value: 'faxtomail',
            //             url: 'https://breezeconnect.com.au/our-policies/',
            //             cost: 5.50,
            //             price_label: '$5.50 per month',
            //
            //         },
            //     ],
            // },
            // //note this is a place holder at this time for Auto Attendants
            // autoattendantPlanData:{
            //     types: [
            //         {
            //             name: 'No Plan',
            //             icon: 'mdi-close-circle-outline',
            //             description: 'Select to have no plan.',
            //             value: 'no-plan',
            //             cost: 0,
            //             price_label: 'No charge',
            //         },
            //         {
            //             name: 'Auto Attendant',
            //             icon: 'mdi-badge-account-outline',
            //             description: 'Auto Attendant Plan',
            //             value: 'ivr-channel-5',
            //             url: 'https://breezeconnect.com.au/our-policies/',
            //             cost: 2.95,
            //             price_label: '$2.95 per month',
            //
            //         },
            //     ],
            // }
        });
    },
};
